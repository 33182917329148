import axios from 'axios';
import { getLocalItem } from '@helpers/storage';
import { Token_Info } from '@constants/common';

let instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-type': 'application/json',
  },
  withCredentials: true,
});

instance.interceptors.request.use(
  (config: any) => {
    const token = getLocalItem(Token_Info.Access_Token); // get token in localstorage
    if (!!token) {
      config.headers['x-access-token'] = token;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (res: any) => {
    return res;
  },
  async (error: any) => {
    return Promise.reject(error);
  },
);

export default instance;
