import moment from 'moment';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ReactCountryFlag from 'react-country-flag';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';

import './ShipmentPending.scss';
import { PATH } from '@/constants/paths';
import { Context } from '../ContextProvider';
import { ShipmentEnum } from '@/constants/common';
import { FORMAT_DATETIME } from '@/constants/date';
import ShipmentDetail from './../Listing/Shipments/Detail';
import { shipment } from '@/@types/api';

const antIconLoading = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ShipmentPending: React.FC<any> = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalDetailOpen, setIsModalDetailOpen] = useState(false);
  const context: any = useContext(Context);
  const { data, selectedRowKeys, onSelectRowChange, isLoadData } = context;

  const columns: ColumnsType<any> = [
    {
      title: 'Store + Order No.',
      dataIndex: 'storeAndOrderNo',
      width: 360,
      fixed: 'left',
      render: context.renderStoreAndOrderNo,
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      render: (date: any, record: shipment) => {
        const isCrossedOut = record?.invalid;

        return (
          <div className={`cell-item ${isCrossedOut && 'crossed-out'}`}>
            {date === null
              ? ''
              : moment(date).format(FORMAT_DATETIME.FULL_DATE_FULL_TIME_AND_SHOW_TWELVE_FORMAT)}
          </div>
        );
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Recipient',
      dataIndex: 'recipient',
      width: 150,
      render: (senderAndReceiver: any, record: shipment, index: number) => {
        const emptyId = context.getEmptyItem(record.id);
        const isCrossedOut = record?.invalid;

        if (emptyId === undefined) {
          return (
            <div className={`cell-item item-center ${isCrossedOut && 'crossed-out'}`}>
              <div className="img">
                {!senderAndReceiver.countryCode ? (
                  <ExclamationCircleOutlined className="icon-alert" />
                ) : (
                  <ReactCountryFlag
                    countryCode={senderAndReceiver.countryCode}
                    svg
                    title={senderAndReceiver.countryCode}
                  />
                )}
              </div>
              <div className="info">
                <p className="info-shop">{senderAndReceiver.name}</p>
                <p className="info-item">{senderAndReceiver.country}</p>
              </div>
            </div>
          );
        } else {
          return (
            <div className="cell-item cell-empty highlight">
              <ExclamationCircleOutlined className="icon-alert" />
            </div>
          );
        }
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Service',
      dataIndex: 'service',
      render: (service: any, record: shipment) => {
        const emptyId = context.getEmptyItem(record.id);
        const isCrossedOut = record?.invalid;

        if (emptyId === undefined && !!service.serviceName) {
          if (!!service.checked) {
            return (
              <div className={`cell-item ${isCrossedOut && 'crossed-out'}`}>
                {service.serviceName}
              </div>
            );
          } else {
            return (
              <div className={`cell-item cell-empty highlight ${isCrossedOut && 'crossed-out'}`}>
                {service.serviceName}
              </div>
            );
          }
        } else {
          return (
            <div className="cell-item cell-empty highlight">
              <ExclamationCircleOutlined className="icon-alert" />
            </div>
          );
        }
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Estimated transit time',
      dataIndex: 'estimatedTransitTime',
      render: (estimatedTransitTime: any, record: shipment) => {
        const emptyId = context.getEmptyItem(record.id);
        const isCrossedOut = record?.invalid;

        if (!emptyId && !!estimatedTransitTime) {
          return (
            <div className={`cell-item ${isCrossedOut && 'crossed-out'}`}>
              {estimatedTransitTime}
            </div>
          );
        } else {
          return (
            <div
              className={`cell-item cell-empty highlight ${isCrossedOut && 'crossed-out'}`}
            ></div>
          );
        }
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'GST',
      dataIndex: 'importTaxAndDuty',
      width: 170,
      render: (importTaxAndDuty: any, record: shipment) => {
        const emptyId = context.getEmptyItem(record.id);
        const isCrossedOut = record?.invalid;

        if (emptyId === undefined) {
          return (
            <div className={`cell-item ${isCrossedOut && 'crossed-out'}`}>
              {importTaxAndDuty !== 'Not Required' &&
              importTaxAndDuty !== '' &&
              importTaxAndDuty !== null ? (
                <p className="tax-and-duty">
                  {`$ ${importTaxAndDuty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </p>
              ) : (
                <p>{importTaxAndDuty}</p>
              )}
            </div>
          );
        } else {
          return;
        }
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Total Cost',
      dataIndex: 'totalCost',
      width: 120,
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
      render: (totalCost: string | number, record: shipment) => {
        const isCrossedOut = record?.invalid;

        return (
          <>
            <div className={`cell-item ${isCrossedOut && 'crossed-out'}`}>
              <p>{totalCost}</p>
            </div>
          </>
        );
      },
    },
  ];

  const rowSelection: any = {
    selectedRowKeys,
    onChange: onSelectRowChange,
    getCheckboxProps: (record: any): any => {
      if (
        (record?.service?.maxItem < record?.storeAndOrderNo?.totalItem ||
          record?.service?.maxWeight < parseInt(record?.storeAndOrderNo?.weight)) &&
        selectedRowKeys.some((x: any) => x !== record?.id)
      ) {
        return {
          disabled: record?.id,
        };
      }
    },
  };

  function onRowClick(record: shipment): void {
    if (
      record.orderType === ShipmentEnum.NOT_READY_TO_BE_SHIPPED ||
      record.orderType === ShipmentEnum.READY_TO_BE_SHIPPED
    ) {
      navigate(`${PATH.SHIPMENTS_MNG_EDIT_SENDERANDRECEIVER}/${record.id}`, {
        state: location.state,
      });
    } else {
      setIsModalDetailOpen(true);
      context.getDetailOfShipment(record.id);
    }
  }

  return (
    <>
      <Table
        className="shipment-table"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        bordered
        pagination={false}
        tableLayout={'fixed'}
        rowClassName={(record, index) =>
          record?.service?.maxItem < record?.storeAndOrderNo?.totalItem ||
          record?.service?.maxWeight < parseInt(record?.storeAndOrderNo?.weight)
            ? 'highlight-row'
            : ''
        }
        scroll={{ x: 1440, y: 600 }}
        loading={{
          spinning: isLoadData ? true : false,
          tip: 'Loading',
          indicator: antIconLoading,
        }}
      />
      {isModalDetailOpen && (
        <ShipmentDetail
          isModalDetailOpen={isModalDetailOpen}
          setIsModalDetailOpen={setIsModalDetailOpen}
        />
      )}
    </>
  );
};
export default ShipmentPending;
