import SpeedpostExpressLogo from '@assets/images/brands/speedpost-express.png';
import SpeedpostPriorityLogo from '@assets/images/brands/speedpost-priority.png';
import SpeedpostEconomyLogo from '@assets/images/brands/speedpost-economy.png';
import SpeedpostStandardLogo from '@assets/images/brands/speedpost-standard.png';
import NSaverLogo from '@assets/images/brands/n-saver.png';
import EzyReturnLogo from '@assets/images/brands/ezyreturn.png';
import ESaverLogo from '@assets/images/brands/e-saver.png';

export const StatusEnum = {
  Created_Successfully: 'Created Successfully',
  Updated_Successfully: 'Updated Successfully',
  Deleted_Successfully: 'Deleted Successfully',
  Deleted_Failed: 'Deleted Failed',
  Updated_Failed: 'Updated Failed!',
  Validate_Failed: 'Validate Failed',
  Api_Error: 'Validate Failed',
  Invalid_Session: 'Invalid Session',
  Deactive_Account: 'Your account is inactive. Please contact your SingPost administrator.',
  Sync_Order: {
    No_New_Order: 'No new orders. Only orders that match your store settings will be synced',
    Successfully: 'order(s) successfully synced.',
    Reconnect_To_Sync_Order: 'Store needs to be reconnected again to sync orders successfully',
  },
  Processing: 'The request is processing',
};

export const Token_Info = {
  Access_Token: 'seller_access_token',
  User_Info: 'seller_user_info',
  Deactive: 'seller_deactive',
  Profile: 'seller_profile',
  Countries: 'seller_countries',
  Store: 'seller_store',
  Address: 'seller_address',
  List_Store: 'seller_list_store',
  Addresses: 'seller_addresses',
  Expired: 'seller_expired',
  Plan: 'seller_plan',
  PlanRequest: 'seller_plan_request',
  Billing_Infomation: 'seller_billing',
};

export const Location_Info = {
  Filters: 'filters',
  TextFilter: 'text_filter',
  OrderFilter: 'order_filter',
  FiltersOptions: 'filters_options',
  PickupFilter: 'pickup_filter',
  FiltersResult: 'filters_result',
};

export const ShipmentEnum = {
  PENDING: 'PENDING', // ready AND not ready
  SHIPMENT: 'SHIPMENT', // basic
  FULFILLED: 'FULFILLED', // pickup has been created

  NOT_READY_TO_BE_SHIPPED: 'NOT_READY',
  READY_TO_BE_SHIPPED: 'READY',
  SHIPMENTS: 'SHIPMENT',
  DESTINATION: 'DESTINATION',
  ORDER_CREATION_DATE: 'ORDER_CREATION_DATE',
  SHIPMENT_CREATION_DATE: 'SHIPMENT_CREATION_DATE',
  STORE_OF_FLATFORM: 'STORE_OF_FLATFORM',
  SHIP_FROM_ADDRESS: 'SHIP_FROM_ADDRESS',
  SERVICE: 'SERVICE',
  DATE_FORMAT: 'YYYY-MM-DD',
  ALL: 'ALL',
  PICKUP_CREATED: 'CREATED',
  NOT_CREATE_PICKUP: 'NOT_CREATED',
  ORDER_STATUS: {
    Ready_To_Be_Shipped: 'Ready to be shipped',
    Not_Ready_To_Be_Shipped: 'Not ready to be shipped',
    Shipments: 'Shipments',

    ALL: 'All',
    PENDING: 'Pending',
    SHIPMENT: 'Shipment',
    FULFILLED: 'Fulfilled',
  },
  PICKUP_STATUS: {
    All: 'All',
    Pickup_Created: 'Pickup created',
    Not_Create_Pickup: 'Not create pickup',
  },
  FILTER_OPTIONS: {
    ADD_FILTER: {
      label: 'Add filter +',
      name: '',
    },
    DESTINATION: {
      label: 'Destination',
      name: 'DESTINATION',
    },
    ORDER_CREATION_DATE: {
      label: 'Order Creation Date',
      name: 'ORDER_CREATION_DATE',
    },
    SHIPMENT_CREATION_DATE: {
      label: 'Shipment Creation Date',
      name: 'SHIPMENT_CREATION_DATE',
    },
    STORE_OF_FLATFORM: {
      label: 'Store of platforms',
      name: 'STORE_OF_FLATFORM',
    },
    SHIP_FROM_ADDRESS: {
      label: 'Ship from address',
      name: 'SHIP_FROM_ADDRESS',
    },
    SERVICE: {
      label: 'Service',
      name: 'SERVICE',
    },
  },
  DATE_PICKER_OPTIONS: {
    Today: {
      name: 'today',
      label: 'Today',
    },
    Last_7_Days: {
      name: 'last-7-days',
      label: 'Last 7 days',
    },
    Last_30_Days: {
      name: 'last-30-days',
      label: 'Last 30 days',
    },
    Last_90_Days: {
      name: 'last-90-days',
      label: 'Last 90 days',
    },
    Last_12_Months: {
      name: 'last-12-months',
      label: 'Last 12 months',
    },
    Custom: {
      name: 'custom',
      label: 'Custom',
    },
  },
  TYPE_OF_SHIPMENTS: {
    Standard_Large: 'Standard Large',
    Standard_Regular: 'Standard Regular',
    Non_Standard: 'Non Standard',
    Custom_Box: 'Custom box',
  },
  SERVICE_STATUS: {
    SYNC_SUCCESSFULLY: 'sync_successfully',
    CHOOSE_SERVICE: 'choose_service',
  },
  ACTION_KEYS: {
    ASSIGN_SHIPMENT: 'assign-shipment',
    REQUEST_LABEL: 'request-label',
    PRINT_LABEL: 'print-labels',
    BULK_EDIT_SHIPMENTS: 'bulk-edit-shipments',
    CREATE_MANUAL_SHIPMENT: 'create-manual-shipment',
    CANCEL: 'cancel',
    EXPORT: 'export',
    SPLIT: 'split',
  },
  EXPORT_SHIPMENTS: {
    LIST_ORDER_EXPORT_ID: 'ListOrderExportId',
    FROM_SHIPMENT_CREATION_DATE: 'FromShipmentCreationDate',
    TO_SHIPMENT_CREATION_DATE: 'ToShipmentCreationDate',
    FROM_ORDER_CREATION_DATE: 'FromOrderCreationDate',
    TO_ORDER_CREATION_DATE: 'ToOrderCreationDate',
    DESTINATION: 'Destination',
    STORE_OF_PLATFORMS: 'StoreOfPlatforms',
    SHIP_FROM_ADDRESS: 'ShipFromAddress',
    SEARCH_TEXT: 'SearchText',
  },
  REFUND_STATUSES: {
    REFUND_REQUEST: 'REFUND_REQUEST',
    CANCEL_REFUND_IN_PROGRESS: 'CANCEL',
    CANCEL_REFUND_DONE: 'CANCELLED',
  },
};

export const PlansEnum = {
  CORPORATE: 'Corporate',
  PAY_PER_USE: 'Pay Per User',
  BASIC: 'basic',
  PREMIUM: 'premium',
  BASIC_CODE: 'P001',
  PREMIUM_CODE: 'P002',
};

export const Holliday = [
  '01-01',
  '02-10',
  '02-11',
  '03-29',
  '04-10',
  '05-01',
  '05-22',
  '06-17',
  '08-09',
  '10-31',
  '12-25',
];

export const PickupEnum = {
  COLLECTION_REQUEST: 'COLLECTION_REQUEST',
  DROPOFF: 'DROPOFF',
  SELF_LODGE: 'SELF_LODGE',
  NORTH_OPTION: {
    LABEL: 'North',
    VALUE: 'north',
  },
  EAST_OPTION: {
    LABEL: 'East',
    VALUE: 'east',
  },
  WEST_OPTION: {
    LABEL: 'West',
    VALUE: 'west',
  },
  SOUTH_OPTION: {
    LABEL: 'South',
    VALUE: 'south',
  },
  NORTH_EAST_OPTION: {
    LABEL: 'North East',
    VALUE: 'north-east',
  },
  SOUTH_EAST_OPTION: {
    LABEL: 'South East',
    VALUE: 'south-east',
  },
  SOUTH_WEST_OPTION: {
    LABEL: 'South West',
    VALUE: 'south-west',
  },
  NEAREST_AVAILABLE_TIME: {
    DATE_IN_WEEK: {
      MORNING: {
        FROM: '09:00',
        TO: '13:00',
      },
      AFTERNOON: {
        FROM: '14:00',
        TO: '18:00',
      },
    },
    DATE_IS_SATURDAY: {
      FROM: '09:00',
      TO: '11:30',
    },
  },
  PICKUP_DETAIL_TYPE: {
    DROP_OFF: 'DROP OFF',
    COLLECTION_REQUEST: 'COLLECTION REQUEST',
    SELF_LODGE: 'SELF LODGE',
  },
};

export const BillingDetailCommonEnum = {
  STATUS: {
    SUCCESSFUL: 'Successful',
    FAIL: 'Fail',
    PENDING: 'Pending',
    REJECTED: 'Rejected',
  },
  SECTIONS: {
    TRANSACTION_INFORMATION: 'Transaction Information',
    SHIPMENTS_INFORMATION: 'Shipments Information',
  },
};

export const CardEnum = {
  CARD_CREATE_STATUSES: {
    SUCCESS: 'success',
    FAILED: '',
  },
  CARD_TYPE: {
    VISA: 'VISA',
    MASTERCARD: 'MASTERCARD',
  },
};

export const InvoicesEnum = {
  STATUS: {
    PAID: 'Paid',
    UNPAID: 'Unpaid',
    OVERDUE: 'Overdue',
  },
};

export const HelpEnum = {
  STEPS: {
    LETS_GO: "LET'S GO",
    SHOW_ME: 'SHOW ME',
    NEXT: 'NEXT',
    GOT_IT: 'GOT IT',
    PREV: 'previous',
    DONE: 'DONE',
  },
};

export const ServiceTypesEnum = {
  SPEEDPOST_EXPRESS_SINGAPORE: 'Speedpost Express Singapore',
  SPEEDPOST_EXPRESS_PUBLIC_HOLIDAY: 'Speedpost Express (Public Holiday)',
  SPEEDPOST_PRIORITY_SERVICE: 'Speedpost Priority Service',
  SPEEDPOST_PRIORITY_SINGAPORE: 'Speedpost Priority Singapore',
  SPEEDPOST_PRIORITY_SERVICE_AO1: 'Speedpost Priority Service (AO1)',
  SPEEDPOST_PRIORITY_SERVICE_AO3: 'Speedpost Priority Service (AO3)',
  SPEEDPOST_ECONOMY_SINGAPORE_DEFERRED_DELIVERY: 'Speedpost Economy Singapore (Deferred Delivery)',
  SPEEDPOST_STANDARD_SINGAPORE: 'Speedpost Standard Singapore',
  SPEEDPOST_STANDARD_SINGAPORE_POPSTATION: 'Speedpost Standard Singapore (POPStation)',
  N_SAVER: 'N-Saver',
  SPEEDPOST_EZYRETURN: 'Speedpost ezyReturn',
  E_SAVER: 'E-Saver',
};

export const ServicesImageListEnum = [
  {
    name: [
      ServiceTypesEnum.SPEEDPOST_EXPRESS_SINGAPORE,
      ServiceTypesEnum.SPEEDPOST_EXPRESS_PUBLIC_HOLIDAY,
    ],
    image: SpeedpostExpressLogo,
  },
  {
    name: [
      ServiceTypesEnum.SPEEDPOST_PRIORITY_SERVICE,
      ServiceTypesEnum.SPEEDPOST_PRIORITY_SINGAPORE,
      ServiceTypesEnum.SPEEDPOST_PRIORITY_SERVICE_AO1,
      ServiceTypesEnum.SPEEDPOST_PRIORITY_SERVICE_AO3,
    ],
    image: SpeedpostPriorityLogo,
  },
  {
    name: [ServiceTypesEnum.SPEEDPOST_ECONOMY_SINGAPORE_DEFERRED_DELIVERY],
    image: SpeedpostEconomyLogo,
  },
  {
    name: [
      ServiceTypesEnum.SPEEDPOST_STANDARD_SINGAPORE,
      ServiceTypesEnum.SPEEDPOST_STANDARD_SINGAPORE_POPSTATION,
    ],
    image: SpeedpostStandardLogo,
  },
  {
    name: [ServiceTypesEnum.N_SAVER],
    image: NSaverLogo,
  },
  {
    name: [ServiceTypesEnum.SPEEDPOST_EZYRETURN],
    image: EzyReturnLogo,
  },
  {
    name: [ServiceTypesEnum.E_SAVER],
    image: ESaverLogo,
  },
];
