import accountApi from '@/apis/workflow/account.api';
import { PlansEnum, StatusEnum, Token_Info } from '@/constants/common';
import { getLocalItem, setLocalItem } from '@/helpers/storage';
import { Button, Divider, Modal, Radio, Spin, Typography, message } from 'antd';
import { useEffect, useState } from 'react';
import './SelectPlanModal.scss';

const { Title, Text } = Typography;

interface PropsType {
  isModalSelectPlan: boolean;
  setIsModalSelectPlan: any;
  setLoadingPlan?: any;
  value: number | string;
  setValue: any;
  loading: boolean;
  setLoading: any;
  dataPlan?: any;
  setDataPlan?: any;
}

const SelectPlanModal = (props: PropsType) => {
  const {
    isModalSelectPlan,
    setIsModalSelectPlan,
    setLoadingPlan,
    value,
    setValue,
    loading,
    setLoading,
    dataPlan,
    setDataPlan,
  } = props;

  const [listPlan, setListPlan] = useState<any>([]);
  const [chosenPlan, setChosenPlan] = useState<any>(null);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    accountApi
      .subcriptionPlan()
      .then(result => {
        if (result.errorCode === '0') {
          setListPlan(result?.Data);
        }
      })
      .catch(info => {
        message.error(StatusEnum.Api_Error);
      });
  }, []);

  useEffect(() => {
    const selectedPlan = listPlan?.find((x: any) => x?.Id === value);
    !!selectedPlan && setChosenPlan(selectedPlan);
  }, [value]);

  const handleCancel = () => {
    if (loading === false) {
      setIsModalSelectPlan(false);
      const plan = getLocalItem(Token_Info?.Plan);
      if (plan === null) {
        setValue(1);
      } else {
        if (plan?.Type === 'PREMIUM') {
          setValue(2);
        }
        setValue(0);
      }
    }
  };

  const hanleUpdate = () => {
    setLoadingButton(true);
    setLoading(true);
    accountApi
      .subcriptionUpdate(value)
      .then(result => {
        if (result.errorCode === '0') {
          message.success(StatusEnum.Updated_Successfully);
          setLoadingButton(false);
          setIsModalSelectPlan(false);
          setLoadingPlan(true);
          setLocalItem(Token_Info?.PlanRequest, true);
          setLoading(false);
        }

        if (result.errorCode === '28806') {
          message.error('You already requested upgrade plan');
          setLoadingButton(false);
          setLoading(false);
        }

        if (result.errorCode === '28805') {
          message.error('Seller does not have a credit card yet');
          setLoadingButton(false);
          setLoading(false);
        }

        if (result.errorCode === '28804') {
          message.error('Please update Billing Information.');
          setLoadingButton(false);
          setLoading(false);
        }

        if (result.errorCode === '28803') {
          message.error('Seller invalid');
          setLoadingButton(false);
          setLoading(false);
        }
      })
      .catch(info => {
        message.error(StatusEnum.Api_Error);
        setLoadingButton(false);
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        width={620}
        open={isModalSelectPlan}
        title={<Title level={3}>Choose your plan</Title>}
        onCancel={handleCancel}
        footer={[]}
        className="select-plan-modal-wrapper"
      >
        <Spin spinning={loading}>
          <Divider style={{ borderTop: '1px solid rgba(0, 0, 0, 0.85)' }} />
          <Title level={4}>Select plan</Title>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Radio.Group
              // onChange={onChange}
              value={value}
              style={{ display: 'contents' }}
            >
              {listPlan?.map((item: any, i: number) => (
                <Button
                  key={i}
                  block
                  size={'large'}
                  style={{ width: '220px', textAlign: 'inherit' }}
                  disabled={
                    item?.Code === PlansEnum.BASIC_CODE && dataPlan?.Code === PlansEnum.BASIC_CODE
                      ? true
                      : false
                  }
                  onClick={() => {
                    setValue(item?.Id);
                  }}
                >
                  <Radio
                    value={item?.Id}
                    disabled={
                      item?.Code === PlansEnum.BASIC_CODE && dataPlan?.Code === PlansEnum.BASIC_CODE
                        ? true
                        : false
                    }
                  >
                    {item?.Name}
                    {item?.Name === 'Corporate' ? <span className='label-best-offer'>Best offer</span> : null}
                  </Radio>
                </Button>
              ))}
            </Radio.Group>
          </div>
          <Title level={4} style={{ marginTop: '25px' }}>
            What you get
          </Title>
          <div className="plan-description">
            {chosenPlan === null ? (
              <>
                <span className="button-sub d-flex">
                  <Text style={{ marginLeft: '8px' }}>
                    <span dangerouslySetInnerHTML={{ __html: `${listPlan[0]?.Description}` }} />
                  </Text>
                </span>
                <br />
              </>
            ) : (
              <>
                <span className="button-sub d-flex">
                  <Text style={{ marginLeft: '8px' }}>
                    <span dangerouslySetInnerHTML={{ __html: `${chosenPlan?.Description}` }} />
                  </Text>
                </span>
                <br />
              </>
            )}
          </div>
          <div className="button-md-subscriptions">
            <Button
              className={value === 0 ? 'button' : 'button-color-md-subscriptions'}
              disabled={value === 0 ? true : false}
              type="primary"
              size={'large'}
              loading={loadingButton}
              onClick={hanleUpdate}
            >
              Upgrade Plan
            </Button>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default SelectPlanModal;
