/**
 * Format date time
 *
 * */
export const FORMAT_DATETIME = {
  SHORT_DATE: 'DD/MM/YYYY',
  LONG_DATE: 'DD MMM YYYY',
  FULL_DATE: 'ddd DD MMM YYYY',
  FULL_TIME: 'DD/MM/YYYY HH:mm:ss',
  FULL_TIME2: 'DD/MM/YYYY - HH:mm:ss',
  FULL_TIME_24: 'DD/MM/YYYY HH:mm a',
  FULL_DATE_FULL_TIME_AND_SHOW_TWELVE_FORMAT: 'DD/MM/YYYY hh:mm:ss A',
  ISO8601: 'YYYY-MM-DD',
  ISO8602: 'YYYY-MM-DDThh:mm:ss',
  SHORT_DATE2: 'MM-DD-YYYY',
  SHORT_DATE3: 'DD-MM-YYYY',
  SHORT_TIME: 'hh:mm A',
  SHORT_TIME2: 'hh:mm',
  LONG_TIME: 'hh:mm:ss',
  PIPE_SHORT_DATE: 'MM/DD/yyyy',
  PIPE_SHORT_DATE_TIME: 'MM/DD/yyyy HH:mm',
  MONTH_AND_YEAR: 'MM/YYYY',
  MONTH_IS_TEXT_AND_SHOW_TIME: 'MMM DD, YYYY LTS',
  MONTH_IS_FULL_TEXT_AND_SHOW_TIME: 'MMMM DD, YYYY LTS',
};
