import ReactCountryFlag from 'react-country-flag';
import { memo, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import './index.scss';
import { Context } from '@/pages/ShipmentsManagement/ContextProvider';
import { useLocation } from 'react-router';

const Addresses = ({ record }: any) => {
  const location = useLocation();
  const context: any = useContext(Context);
  const [shipFromAddress, setShipFromAddress]: any = useState({});
  const [shipToAddress, setShipToAddress]: any = useState({});

  useEffect(() => {
    if (context === null && location.search !== '') {
      if (Object.keys(record)?.length > 0 && record?.Addresses !== undefined) {
        setShipFromAddress(record?.Addresses?.ShipFrom);
        setShipToAddress(record?.Addresses?.ShipTo);
      }
    } else {
      const { detailData } = context;
      if (detailData && detailData?.Addresses !== undefined) {
        setShipFromAddress(detailData?.Addresses?.ShipFrom);
        setShipToAddress(detailData?.Addresses?.ShipTo);
      }
    }
  }, [record]);

  return (
    <>
      <div className="content-wrapper">
        <Row className="row-wrapper">
          <Col span={2} className="address-type">
            <p>Sender</p>
          </Col>
          <Col span={22} className="group">
            {shipFromAddress?.ContactName !== undefined && (
              <p className="group-item contact-name">{shipFromAddress?.ContactName}</p>
            )}

            {shipFromAddress?.Country !== undefined &&
              shipFromAddress?.AdressLine1 !== undefined && (
                <div className="shipping-address group-item">
                  <div className="country-img">
                    <ReactCountryFlag
                      countryCode={shipFromAddress?.Country}
                      svg
                      title={shipFromAddress?.Country}
                    />
                  </div>
                  <p>
                    {shipFromAddress?.AdressLine1} - {shipFromAddress?.PostalCode}
                  </p>
                </div>
              )}

            {shipFromAddress?.ContactNo !== undefined && shipFromAddress?.Email !== undefined && (
              <p className="group-item contact-no-and-email">
                {shipFromAddress?.ContactNo} - {shipFromAddress?.Email}
              </p>
            )}
          </Col>
        </Row>
      </div>
      <div className="content-wrapper">
        <Row className="row-wrapper">
          <Col span={2} className="address-type">
            <p>Recipient</p>
          </Col>
          <Col span={22} className="group">
            {shipToAddress?.ContactName !== undefined && (
              <p className="group-item contact-name">{shipToAddress?.ContactName}</p>
            )}

            {shipToAddress?.Country !== undefined && shipToAddress?.AdressLine1 !== undefined && (
              <div className="shipping-address group-item">
                <div className="country-img">
                  <ReactCountryFlag
                    countryCode={shipToAddress?.Country}
                    svg
                    title={shipToAddress?.Country}
                  />
                </div>
                <p>
                  {shipToAddress?.AdressLine1} - {shipToAddress?.PostalCode}
                </p>
              </div>
            )}

            {shipToAddress?.ContactNo !== undefined && shipToAddress?.Email !== undefined && (
              <p className="group-item contact-no-and-email">
                {shipToAddress?.ContactNo} - {shipToAddress?.Email}
              </p>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default memo(Addresses);
