import {
  order,
  service,
  shipment_create_manual,
  shipment_edit,
  shipment_edit_manual,
} from '@/@types/api';
import BaseApi from '@apis/base.api';
import httpClient from '@apis/http-client.api';
import { shipment } from '../../@types/api';

class shipmentApi extends BaseApi {
  baseApi: string = '/spf/shipment';
  orderApi: string = '/order';
  cancelOrderApi: string = '/cancelOrder';

  async getShipmentInformationCommon(): Promise<any> {
    try {
      const response = await httpClient.httpGet(
        `${this.baseApi}${this.orderApi}/information/common`,
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async editOrder(shipmentId: string | number, data: shipment_edit): Promise<any> {
    try {
      const response = await httpClient.httpPatch(
        `${this.baseApi}${this.orderApi}/edit/${shipmentId}`,
        data,
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getOrderList(props: order): Promise<any> {
    const {
      OrderType,
      Page,
      PerPage,
      FromShipmentCreationDate,
      ToShipmentCreationDate,
      FromOrderCreationDate,
      ToOrderCreationDate,
      Destination,
      StoreOfPlatforms,
      ShipFromAddress,
      SearchText,
    } = props;

    try {
      const response = await httpClient.httpGet(
        `${this.baseApi}${this.orderApi}/list?OrderType=${OrderType}&Page=${Page}&PerPage=${PerPage}&FromShipmentCreationDate=${FromShipmentCreationDate}&ToShipmentCreationDate=${ToShipmentCreationDate}&FromOrderCreationDate=${FromOrderCreationDate}&ToOrderCreationDate=${ToOrderCreationDate}&Destination=${Destination}&StoreOfPlatforms=${StoreOfPlatforms}&ShipFromAddress=${ShipFromAddress}&SearchText=${SearchText}`,
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getShipmentsList(props: order): Promise<any> {
    const {
      Page,
      PerPage,
      OrderType,
      FromShipmentCreationDate,
      ToShipmentCreationDate,
      FromOrderCreationDate,
      ToOrderCreationDate,
      Destination,
      StoreOfPlatforms,
      ShipFromAddress,
      Service,
      SearchText,
    } = props;

    const newSearchText = escape(SearchText + '');

    try {
      const response = await httpClient.httpGet(
        `/spf/shipment/list?OrderType=${OrderType}&Page=${Page}&PerPage=${PerPage}&FromShipmentCreationDate=${FromShipmentCreationDate}&ToShipmentCreationDate=${ToShipmentCreationDate}&FromOrderCreationDate=${FromOrderCreationDate}&ToOrderCreationDate=${ToOrderCreationDate}&Destination=${Destination}&StoreOfPlatforms=${StoreOfPlatforms}&ShipFromAddress=${ShipFromAddress}&Service=${Service}&SearchText=${SearchText}`,
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getShipmentDetail(ShipmentId: string | number): Promise<any> {
    try {
      const response = await httpClient.httpGet(`${this.baseApi}/detail?ShipmentId=${ShipmentId}`);
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getShipmentCommonList(): Promise<any> {
    try {
      const response = await httpClient.httpGet(`${this.baseApi}/list/common`);
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async createShipments(ListOrderId: string[] | number[]): Promise<any> {
    try {
      const response = await httpClient.httpPost(`${this.baseApi}/create`, {
        ListOrderId,
      });
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getServiceList(data: service): Promise<any> {
    try {
      const response = await httpClient.httpPost(
        `${this.baseApi}${this.orderApi}/service/list`,
        data,
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async syncOrder(ShopifyStoreId: string | number): Promise<any> {
    try {
      const response = await httpClient.httpGet(
        `${this.baseApi}${this.orderApi}/sync/${ShopifyStoreId}`,
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getLabel(shipmentIds: any): Promise<any> {
    try {
      const response = await httpClient.httpPost(`${this.baseApi}/printLabel`, {
        shipmentIds,
      });
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async bulkEdit(props: any): Promise<any> {
    try {
      const response = await httpClient.httpPost(`${this.baseApi}${this.orderApi}/bulkEdit`, props);
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async bulkEditGetListService(shipmentIds: string): Promise<any> {
    try {
      const response = await httpClient.httpGet(
        `${this.baseApi}${this.orderApi}/bulkEdit/service/list?ListOrderId=${shipmentIds}`,
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async createManualOrder(data: shipment_create_manual): Promise<any> {
    try {
      const response = await httpClient.httpPost(
        `${this.baseApi}${this.orderApi}/createManual`,
        data,
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async editManualOrder(ShipmentId: string | number, data: shipment_edit_manual): Promise<any> {
    try {
      const response = await httpClient.httpPatch(
        `${this.baseApi}${this.orderApi}/editManual/${ShipmentId}`,
        data,
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async createTag(Tag: string): Promise<any> {
    try {
      const response = await httpClient.httpPost(`${this.baseApi}${this.orderApi}/tag/create`, {
        Tag,
      });
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async cancelOrders(ShipmentIds: string[] | number[]): Promise<any> {
    try {
      const response = await httpClient.httpPost(`${this.baseApi}${this.cancelOrderApi}s`, {
        ShipmentIds,
      });
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getShipmentsSummary(ListOrderId: string[] | number[]): Promise<any> {
    try {
      const response = await httpClient.httpGet(
        `${this.baseApi}/summary?ListOrderId=${ListOrderId}`,
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getShipmentsSplit(shipmentId: string | number): Promise<any> {
    try {
      const response = await httpClient.httpGet(
        `${this.baseApi}/split-shipment/detail/${shipmentId}`,
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async splitShipment(shipmentId: string | number, listItem: any): Promise<any> {
    try {
      const response = await httpClient.httpPost(
        `${this.baseApi}/split-shipment/split/${shipmentId}`,
        {
          splitItems: listItem,
        },
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }
}

export default new shipmentApi();
