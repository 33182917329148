import { memo, useContext, useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Row, Col, Table } from 'antd';

import './index.scss';
import { Context } from '@/pages/ShipmentsManagement/ContextProvider';
import { useLocation } from 'react-router';

const ShipmentInformation = ({ record }: any) => {
  const location = useLocation();
  const context: any = useContext(Context);
  const [listData, setListData] = useState<any>([]);
  const [detailData, setDetailData]: any = useState({});

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      width: '10%',
      align: 'center' as 'center',
    },
    {
      title: 'Item Description',
      dataIndex: 'ItemDescription',
      key: 'ItemDescription',
      width: '40%',
    },
    {
      title: 'Country of manufacture*',
      dataIndex: 'CountryNameOfManufature',
      key: 'CountryNameOfManufature',
      width: '40%',
    },
    {
      title: 'HS Tariff Code',
      key: 'HSTariffCode',
      dataIndex: 'HSTariffCode',
      width: '30%',
    },
    {
      title: 'Declared value',
      key: 'DeclaredValue',
      width: '30%',
      render: (_: any, record: any) => <p className="value">{record.DeclaredValue} SGD</p>,
    },
    {
      title: 'Item weight',
      key: 'ItemWeight',
      width: '30%',
      render: (_: any, record: any) => <p className="value">{record.ItemWeight} kg</p>,
    },
    {
      title: 'Qty',
      key: 'Qly',
      dataIndex: 'Qly',
      width: '20%',
    },
    {
      title: 'Total Item Price',
      key: 'TotalItemPrice',
      dataIndex: 'TotalItemPrice',
      width: '30%',
    },
  ];

  useEffect(() => {
    if (context === null && location.search !== '') {
      if (Object.keys(record)?.length > 0 && record?.ShipmentInformationItem !== undefined) {
        const results = record?.ShipmentInformationItem?.map((item: any, index: number) => ({
          key: index + 1,
          ItemDescription: item.ItemDescription,
          CountryNameOfManufature: item.CountryNameOfManufature,
          HSTariffCode: item.HSTariffCode,
          DeclaredValue: item.DeclaredValue,
          ItemWeight: item.ItemWeight,
          Qly: item.Qly,
          TotalItemPrice: item.TotalItemPrice,
        }));
        setListData(results);
        setDetailData(record);
      }
    } else {
      if (context?.detailData && context?.detailData?.ShipmentInformationItem !== undefined) {
        const results = detailData?.ShipmentInformationItem?.map((item: any, index: number) => ({
          key: index + 1,
          ItemDescription: item.ItemDescription,
          CountryNameOfManufature: item.CountryNameOfManufature,
          HSTariffCode: item.HSTariffCode,
          DeclaredValue: item.DeclaredValue,
          ItemWeight: item.ItemWeight,
          Qly: item.Qly,
          TotalItemPrice: item.TotalItemPrice,
        }));
        setListData(results);
        setDetailData(context?.detailData);
      }
    }
  }, [record, context?.detailData]);

  return (
    <>
      <div className="content-group">
        <div className="content-group-items">
          <div className="row-items common-info">
            <div className="item default-field">
              <p className="label">Store name</p>
              <p className="value">{detailData.StoreName}</p>
            </div>
            <div className="item default-field">
              <p className="label">Order number</p>
              <p className="value">#{detailData.OrderNumber}</p>
            </div>
            <div className="item default-field">
              <p className="label">Tags</p>
              <div className="tags">
                {detailData.ShipmentInformation?.Tags?.map((tag: string, index: number) => (
                  <p className="tag" key={index}>
                    {tag}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <div className="row-items common-info">
            <div className="item">
              <p className="label">Seller's Notes</p>
              <p className="value">{detailData.ShipmentInformation?.SellerNotes}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="content-group">
        <div className="content-group-items">
          <Row className="row-items shipment-row-1">
            <div className="container">
              <Col className="item" span={5}>
                <p className="label">Type of shipment</p>
                <p className="value">{detailData.ShipmentInformation?.TypeOfShipment.Type}</p>
              </Col>
              <Col className="item" span={5}>
                <p className="label">Total declared value*</p>
                <p className="value">{detailData.ShipmentInformation?.TotalDeclaredValue} SGD</p>
              </Col>
              <Col className="item" span={12}>
                <p className="label">Measurements</p>
                <Row className="d-flex align-items-center measurements">
                  <Col span={5} className="measurements-item">
                    <p className="value">{detailData.ShipmentInformation?.Measurements.Length}</p>
                  </Col>
                  <Col span={2} className="measurements-item">
                    <span>
                      <CloseOutlined />
                    </span>
                  </Col>
                  <Col span={5} className="measurements-item">
                    <p className="value">{detailData.ShipmentInformation?.Measurements.Width}</p>
                  </Col>
                  <Col span={2} className="measurements-item">
                    <span>
                      <CloseOutlined />
                    </span>
                  </Col>
                  <Col span={5} className="measurements-item">
                    <p className="value">{detailData.ShipmentInformation?.Measurements.Height}</p>
                  </Col>
                  <Col span={3} className="measurements-item">
                    <span>cm</span>
                  </Col>
                </Row>
              </Col>
              <Col className="item" span={3}>
                <p className="label">Weight</p>
                <p className="value">{detailData.ShipmentInformation?.Weight} kg</p>
              </Col>
              <Col className="item" span={4}>
                <p className="label">Type of goods</p>
                <p className="value">{detailData.ShipmentInformation?.TypeOfGoods?.Name}</p>
              </Col>
            </div>
          </Row>
          <Table
            className="table-shipmment-information"
            columns={columns}
            dataSource={listData}
            tableLayout="fixed"
            pagination={false}
            scroll={{ x: 1200 }}
          />
        </div>
      </div>
    </>
  );
};

export default memo(ShipmentInformation);
