import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Modal, Table, Typography, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useLocation, useNavigate } from 'react-router-dom';

import accountApi from '@/apis/workflow/account.api';
import SelectPlanModal from '@/components/PlanModal/SelectPlanModal/SelectPlanModal';
import { ShipmentEnum, StatusEnum } from '@/constants/common';
import { FORMAT_DATETIME } from '@/constants/date';
import { PATH } from '@/constants/paths';
import CreateShipmentTour from '@/pages/Help/Tours/CreateShipment';
import FileIcon from '@assets/images/file.png';
import { Context } from '../ContextProvider';
import ShipmentDetail from './../Listing/Shipments/Detail';
import './ShipmentGeneral.scss';

const { Title, Text } = Typography;
const antIconLoading = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ShipmentGeneral: React.FC<any> = props => {
  const checkedRowRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isModalDetailOpen, setIsModalDetailOpen] = useState(false);
  const context: any = useContext(Context);
  const [isModalPlan, setIsModalPlan] = useState(false);
  const [isModalSelectPlan, setIsModalSelectPlan] = useState(false);
  const [planCheckedValue, setPlanCheckedValue] = useState<any>(0);
  const [listPlan, setListPlan] = useState<any>([]);
  const [openCreateShipmentTour, setOpenCreateShipmentTour] = useState(false);
  const { data, selectedRowKeys, onSelectRowChange, isLoadData, setSelectedRowKeys, storeOptions } =
    context;

  const columns: ColumnsType<any> = [
    {
      title: 'Singpost ID',
      dataIndex: 'singpostId',
      fixed: 'left',
      render: (singpostId: any) => <div className="cell-item">{singpostId}</div>,
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Store + Order No.',
      dataIndex: 'storeAndOrderNo',
      width: 360,
      fixed: 'left',
      render: context.renderStoreAndOrderNo,
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      render: (date: any) => (
        <div className="cell-item">
          {date === null
            ? ''
            : moment(date).format(FORMAT_DATETIME.FULL_DATE_FULL_TIME_AND_SHOW_TWELVE_FORMAT)}
        </div>
      ),
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Recipient',
      dataIndex: 'recipient',
      width: 150,
      render: (senderAndReceiver: any, record: any, index: number) => {
        const emptyId = context.getEmptyItem(record.id);

        if (emptyId === undefined) {
          return (
            <div className="cell-item item-center">
              <div className="img">
                {!senderAndReceiver.countryCode ? (
                  <ExclamationCircleOutlined className="icon-alert" />
                ) : (
                  <ReactCountryFlag
                    countryCode={senderAndReceiver.countryCode}
                    svg
                    title={senderAndReceiver.countryCode}
                  />
                )}
              </div>
              <div className="info">
                <p className="info-shop">{senderAndReceiver.name}</p>
                <p className="info-item">{senderAndReceiver.country}</p>
              </div>
            </div>
          );
        } else {
          return (
            <div className="cell-item cell-empty highlight">
              <ExclamationCircleOutlined className="icon-alert" />
            </div>
          );
        }
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Service',
      dataIndex: 'service',
      render: (service: any, record: any) => {
        const emptyId = context.getEmptyItem(record.id);
        if (emptyId === undefined && !!service.serviceName) {
          if (!!service.checked) {
            return <div className="cell-item">{service.serviceName}</div>;
          } else {
            return <div className="cell-item cell-empty highlight">{service.serviceName}</div>;
          }
        } else {
          return (
            <div className="cell-item cell-empty highlight">
              <ExclamationCircleOutlined className="icon-alert" />
            </div>
          );
        }
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Estimated transit time',
      dataIndex: 'estimatedTransitTime',
      width: 180,
      render: (estimatedTransitTime: any, record: any) => {
        const emptyId = context.getEmptyItem(record.id);
        if (!emptyId && !!estimatedTransitTime) {
          return <div className="cell-item">{estimatedTransitTime}</div>;
        } else {
          return <div className="cell-item cell-empty highlight"></div>;
        }
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Tracking No.',
      dataIndex: 'trackingNo',
      width: 145,
      render: (tracking: any, record: any) => (
        <div className="cell-item">
          <a
            href={record.trackingLink}
            className="link"
            target="_blank"
            rel="tracking link noreferrer"
            onClick={e => {
              e.stopPropagation();
              setIsModalDetailOpen(prev => false);
            }}
          >
            {tracking}
          </a>
        </div>
      ),
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Label',
      dataIndex: 'label',
      width: 70,
      align: 'center',
      render: (label: any, record: any) => {
        return label !== null ? (
          <div className="cell-item label">
            <a
              href={label}
              className="link"
              target="_blank"
              onClick={e => {
                e.stopPropagation();
                setIsModalDetailOpen(prev => false);
              }}
              rel="label noreferrer"
            >
              <img src={FileIcon} width={30} height={30} alt="label" />
            </a>
          </div>
        ) : null;
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Order Status',
      dataIndex: 'shipmentStatus',
      render: (shipmentStatus: any) => <div className="cell-item">{shipmentStatus}</div>,
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'GST',
      dataIndex: 'importTaxAndDuty',
      width: 170,
      render: (importTaxAndDuty: any, record: any) => {
        const emptyId = context.getEmptyItem(record.id);

        if (emptyId === undefined) {
          return (
            <div className="cell-item">
              {importTaxAndDuty !== 'Not Required' &&
              importTaxAndDuty !== '' &&
              importTaxAndDuty !== null ? (
                <p className="tax-and-duty">
                  {`$ ${importTaxAndDuty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </p>
              ) : (
                <p>{importTaxAndDuty}</p>
              )}
            </div>
          );
        } else {
          return;
        }
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Total Cost',
      dataIndex: 'totalCost',
      width: 120,
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectRowChange,
    getCheckboxProps: (record: any) => ({
      disabled: false,
    }),
    renderCell: (value: boolean, record: any, index: number, originNode: React.ReactNode) => {
      return openCreateShipmentTour ? (
        <div
          ref={value ? checkedRowRef : undefined}
          onClick={() => {
            if (location.state?.tourName && openCreateShipmentTour)
              navigate(location.pathname, {
                state: {
                  ...StaticRange,
                  tourName: 'create-shipment',
                  isCreateShipmentTour: {
                    step5: true,
                  },
                  isShipmentActive: true,
                },
              });
          }}
        >
          <Checkbox checked={value} />
        </div>
      ) : (
        originNode
      );
    },
  };

  function onRowClick(record: any): void {
    if (
      record.orderType === ShipmentEnum.NOT_READY_TO_BE_SHIPPED ||
      record.orderType === ShipmentEnum.READY_TO_BE_SHIPPED
    ) {
      navigate(`${PATH.SHIPMENTS_MNG_EDIT_SENDERANDRECEIVER}/${record.id}`, {
        state: location.state,
      });
    } else {
      setIsModalDetailOpen(true);
      context.getDetailOfShipment(record.id);
    }
  }

  useEffect(() => {
    accountApi.subcriptionList().then(result => {
      if (result.errorCode === '0' && result?.Data) {
        if (location.state?.isCreateShipmentTour) {
          setIsModalPlan(false);
        } else if (result.Data?.Plans === null) {
          setIsModalPlan(true);
        }
      }
    });

    accountApi
      .subcriptionPlan()
      .then(result => {
        if (result.errorCode === '0') {
          setListPlan(result?.Data);
          setPlanCheckedValue(1);
        }
      })
      .catch(info => {
        message.error(StatusEnum.Api_Error);
      });
  }, []);

  useEffect(() => {
    if (location.state && location.state?.isCreateShipmentTour) {
      if (location.state.isCreateShipmentTour?.step4 && storeOptions?.length > 0) {
        const id = data && data[0] && data[0]?.id;
        setSelectedRowKeys([id]);
        setOpenCreateShipmentTour(true);
      } else {
        setOpenCreateShipmentTour(false);
      }
    } else {
      setOpenCreateShipmentTour(false);
    }
  }, [location.state]);

  return (
    <>
      <Table
        className="shipment-table"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        bordered
        pagination={false}
        tableLayout={'fixed'}
        scroll={{ x: 1680, y: 600 }}
        loading={{
          spinning: isLoadData ? true : false,
          tip: 'Loading',
          indicator: antIconLoading,
        }}
      />
      {isModalDetailOpen && (
        <ShipmentDetail
          isModalDetailOpen={isModalDetailOpen}
          setIsModalDetailOpen={setIsModalDetailOpen}
        />
      )}
      <Modal
        width={620}
        className="modal-mainboar"
        open={isModalPlan}
        title={<Title level={2}> You've reached the end of your trial.</Title>}
        onCancel={() => setIsModalPlan(false)}
        footer={[]}
      >
        <Divider style={{ borderTop: '1px solid rgba(0, 0, 0, 0.85)' }} />
        <Text>
          Please update your billing information to select your plan and continue shipping.
        </Text>
        <Divider style={{ borderTop: '1px solid rgba(0, 0, 0, 0.85)' }} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            className="button-color-gen-md-subscriptions"
            type="primary"
            size={'large'}
            style={{ width: '220px' }}
            onClick={() => setIsModalSelectPlan(true)}
          >
            See more plan
          </Button>
          <Button
            className="button-color-gen-default-subscriptions"
            size={'large'}
            style={{ width: '220px' }}
            onClick={() => navigate(PATH.ACCOUNT_INFORMATIONS)}
          >
            Update Billing information
          </Button>
        </div>
      </Modal>
      <SelectPlanModal
        isModalSelectPlan={isModalSelectPlan}
        setIsModalSelectPlan={setIsModalSelectPlan}
        value={planCheckedValue}
        setValue={setPlanCheckedValue}
        loading={loading}
        setLoading={setLoading}
      />
      {openCreateShipmentTour && (
        <CreateShipmentTour
          open={openCreateShipmentTour}
          setOpen={setOpenCreateShipmentTour}
          forwardRef={checkedRowRef}
          step={4}
        />
      )}
    </>
  );
};
export default ShipmentGeneral;
