import ShipmentBasic from '@/pages/ShipmentsManagement/ShipmentBasic/ShipmentBasic';
import ShipmentFulfilled from '@/pages/ShipmentsManagement/ShipmentFulfilled/ShipmentFulfilled';
import ShipmentGeneral from '@/pages/ShipmentsManagement/ShipmentGeneral/ShipmentGeneral';
import ShipmentPending from '@/pages/ShipmentsManagement/ShipmentPending/ShipmentPending';
import Loading from '@components/Loading/Loading';
import { PATH } from '@constants/paths';
import withHook from '@hooks/withHook';
import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const HomePage = React.lazy(() => import('src/pages/Home/Home'));
const NotFound = React.lazy(() => import('@pages/NotFound/NotFound'));
const PrivacyPolicy = React.lazy(() => import('@/pages/PrivacyPolicy/PrivacyPolicy'));
const TermOfUse = React.lazy(() => import('@/pages/TermOfUse/TermOfUse'));
// Authen
const LoginPage = React.lazy(() => import('src/pages/Login/Login'));
const VerifyStore = React.lazy(() => import('@/pages/VerifyStore'));
const Register = React.lazy(() => import('@/pages/Register'));
const RegisterSeller = React.lazy(() => import('@/pages/Register/pages/Seller'));
const RegisterStore = React.lazy(() => import('@/pages/Register/pages/Store'));
const RegisterShippingAddress = React.lazy(() => import('@/pages/Register/pages/ShippingAddress'));
const RegisterInformation = React.lazy(() => import('@/pages/Register/pages/Information'));
const RegisterBilling = React.lazy(() => import('@/pages/Register/pages/Billing'));
const RegisterAgreement = React.lazy(() => import('@/pages/Register/pages/Agreement'));
const RegisterSelectPlan = React.lazy(() => import('@/pages/Register/pages/SelectPlan'));
const RegisterCorppassCallBack = React.lazy(() => import('@/pages/Register/pages/Callback'));
const ForgotPassword = React.lazy(() => import('src/pages/ForgotPassword/ForgotPassword'));
const SetUpNewPass = React.lazy(
  () => import('src/pages/ForgotPassword/pages/SetUpNewPass/SetUpNewPass'),
);
// Connect
const Connect = React.lazy(() => import('@/pages/Connect/Connect'));
// Account
const Address = React.lazy(() => import('@/pages/Account/Address/Address'));
const Subscriptions = React.lazy(() => import('@/pages/Account/Subscription/index'));
// Report
const ReportOrder = React.lazy(() => import('@/pages/Report/Order/Order'));
const ReportSales = React.lazy(() => import('@/pages/Report/Sales/Sales'));
// Manage Shipments
const ShipmentsMng = React.lazy(() => import('@/pages/ShipmentsManagement/Listing'));
const ShipmentsManagement = React.lazy(
  () => import('@/pages/ShipmentsManagement/ShipmentsManagement'),
);
const ShipmentsMngCreateManualSenderAndReceiver = React.lazy(
  () => import('src/pages/ShipmentsManagement/CreateManual/SenderAndReceiver/SenderAndReceiver'),
);
const ShipmentsMngCreateManualShipment = React.lazy(
  () => import('src/pages/ShipmentsManagement/CreateManual/Shipment/Shipment'),
);
const ShipmentsMngCreateManualService = React.lazy(
  () => import('src/pages/ShipmentsManagement/CreateManual/Service/Service'),
);
const ShipmentsMngEditSenderAndReceiver = React.lazy(
  () => import('src/pages/ShipmentsManagement/Edit/SenderAndReceiver/SenderAndReceiver'),
);
const ShipmentsMngEditShipment = React.lazy(
  () => import('src/pages/ShipmentsManagement/Edit/Shipment/Shipment'),
);
const ShipmentsMngEditService = React.lazy(
  () => import('src/pages/ShipmentsManagement/Edit/Service/Service'),
);
const AccountInformation = React.lazy(() => import('src/pages/Account/AccountInformation/index'));
// Pickup
const CreatePickup = React.lazy(() => import('@/pages/Pickup/Create'));
const Pickup = React.lazy(() => import('@/pages/Pickup/Listing'));
const PickupDropOff = React.lazy(() => import('@/pages/Pickup/Listing'));
const PickupCollectionRequest = React.lazy(() => import('@/pages/Pickup/Listing'));
const PickupSelfLodge = React.lazy(() => import('@/pages/Pickup/Listing'));
// Setting
const AccountBoxes = React.lazy(() => import('@/pages/Account/Boxes/Boxes'));
// Billing
const Transactions = React.lazy(() => import('@/pages/Billing/Transactions/Transactions'));
//Help
const Help = React.lazy(() => import('@/pages/Help/Help'));
// FAQ
const FAQ = React.lazy(() => import('@pages/FAQDetail/FAQDetail'));

class AppRoutes extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  public render(): React.ReactNode {
    const [token, setToken] = this.props.useState;
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route
            path={`${PATH.HOME}*`}
            index
            element={
              <React.Suspense fallback={<Loading />}>
                <HomePage />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.PRIVACY_POLICY}
            element={
              <React.Suspense fallback={<Loading />}>
                <PrivacyPolicy />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.TERM_OF_USE}
            element={
              <React.Suspense fallback={<Loading />}>
                <TermOfUse />
              </React.Suspense>
            }
          />
          {/* Authen */}
          <Route
            path={PATH.VERIFYSTORE}
            element={
              <React.Suspense fallback={<Loading />}>
                <VerifyStore />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.REGISTER_SELLER}
            element={
              <React.Suspense fallback={<Loading />}>
                <RegisterSeller />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.REGISTER}
            element={
              <React.Suspense fallback={<Loading />}>
                <Register />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.REGISTER_STORE}
            element={
              <React.Suspense fallback={<Loading />}>
                <RegisterStore />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.REGISTER_SHIPPINGADDRESS}
            element={
              <React.Suspense fallback={<Loading />}>
                <RegisterShippingAddress />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.REGISTER_INFORMATION}
            element={
              <React.Suspense fallback={<Loading />}>
                <RegisterInformation />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.REGISTER_BILLING}
            element={
              <React.Suspense fallback={<Loading />}>
                <RegisterBilling />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.REGISTER_AGREEMENT}
            element={
              <React.Suspense fallback={<Loading />}>
                <RegisterAgreement />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.REGISTER_SELECT_PLAN}
            element={
              <React.Suspense fallback={<Loading />}>
                <RegisterSelectPlan />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.REGISTER_CORPPASS_CALLBACK}
            element={
              <React.Suspense fallback={<Loading />}>
                <RegisterCorppassCallBack />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.LOGIN}
            element={
              <React.Suspense fallback={<Loading />}>
                <LoginPage />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.FORGOT_PASSWORD}
            element={
              <React.Suspense fallback={<Loading />}>
                <ForgotPassword />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.SETUP_NEW_PASS}
            element={
              <React.Suspense fallback={<Loading />}>
                <SetUpNewPass />
              </React.Suspense>
            }
          />
          {/* Account */}
          <Route
            path={`/*`}
            index
            element={
              <React.Suspense fallback={<Loading />}>
                <HomePage />
              </React.Suspense>
            }
          ></Route>
          <Route
            path={PATH.ADDRESS}
            element={
              <React.Suspense fallback={<Loading />}>
                <Address />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.ACCOUNT_INFORMATIONS}
            element={
              <React.Suspense fallback={<Loading />}>
                <AccountInformation />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.ACCOUNT_BOXES}
            element={
              <React.Suspense fallback={<Loading />}>
                <AccountBoxes />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.SUBSCRIPTION}
            element={
              <React.Suspense fallback={<Loading />}>
                <Subscriptions />
              </React.Suspense>
            }
          />
          {/* Shipments management */}
          <Route
            path={PATH.SHIPMENTS_MNG}
            element={
              <React.Suspense fallback={<Loading />}>
                <ShipmentsManagement>
                  <ShipmentGeneral />
                </ShipmentsManagement>
              </React.Suspense>
            }
          />
          <Route
            path={PATH.SHIPMENTS_MNG_PENDING}
            element={
              <React.Suspense fallback={<Loading />}>
                <ShipmentsManagement>
                  <ShipmentPending />
                </ShipmentsManagement>
              </React.Suspense>
            }
          />
          <Route
            path={PATH.SHIPMENTS_MNG_SHIPMENT}
            element={
              <React.Suspense fallback={<Loading />}>
                <ShipmentsManagement>
                  <ShipmentBasic />
                </ShipmentsManagement>
              </React.Suspense>
            }
          />
          <Route
            path={PATH.SHIPMENTS_MNG_FULFILLED}
            element={
              <React.Suspense fallback={<Loading />}>
                <ShipmentsManagement>
                  <ShipmentFulfilled />
                </ShipmentsManagement>
              </React.Suspense>
            }
          />
          <Route
            path={PATH.NEW_SHIPMENT_CREATE_SENDERANDRECEIVER}
            element={
              <React.Suspense fallback={<Loading />}>
                <ShipmentsMngCreateManualSenderAndReceiver />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.NEW_SHIPMENT_CREATE_SHIPMENT}
            element={
              <React.Suspense fallback={<Loading />}>
                <ShipmentsMngCreateManualShipment />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.NEW_SHIPMENT_CREATE_SERVICE}
            element={
              <React.Suspense fallback={<Loading />}>
                <ShipmentsMngCreateManualService />
              </React.Suspense>
            }
          />
          <Route
            path={`${PATH.SHIPMENTS_MNG_EDIT_SENDERANDRECEIVER}/:id`}
            element={
              <React.Suspense fallback={<Loading />}>
                <ShipmentsMngEditSenderAndReceiver />
              </React.Suspense>
            }
          />
          <Route
            path={`${PATH.SHIPMENTS_MNG_EDIT_SHIPMENT}/:id`}
            element={
              <React.Suspense fallback={<Loading />}>
                <ShipmentsMngEditShipment />
              </React.Suspense>
            }
          />
          <Route
            path={`${PATH.SHIPMENTS_MNG_EDIT_SERVICE}/:id`}
            element={
              <React.Suspense fallback={<Loading />}>
                <ShipmentsMngEditService />
              </React.Suspense>
            }
          />
          {/* Report */}
          <Route
            path={PATH.REPORT_ORDER}
            element={
              <React.Suspense fallback={<Loading />}>
                <ReportOrder />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.REPORT_SALES}
            element={
              <React.Suspense fallback={<Loading />}>
                <ReportSales />
              </React.Suspense>
            }
          />
          {/* Pickup */}
          <Route
            path={PATH.CREATE_PICKUP}
            element={
              <React.Suspense fallback={<Loading />}>
                <CreatePickup />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.PICKUP}
            element={
              <React.Suspense fallback={<Loading />}>
                <Pickup />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.PICKUP_DROP_OFF}
            element={
              <React.Suspense fallback={<Loading />}>
                <PickupDropOff />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.PICKUP_COLLECTION_REQUEST}
            element={
              <React.Suspense fallback={<Loading />}>
                <PickupCollectionRequest />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.PICKUP_SELF_LODGE}
            element={
              <React.Suspense fallback={<Loading />}>
                <PickupSelfLodge />
              </React.Suspense>
            }
          />
          {/* Connect */}
          <Route
            path={PATH.CONNECTWITHPARAM}
            element={
              <React.Suspense fallback={<Loading />}>
                <Connect />
              </React.Suspense>
            }
          />
          {/* Billing */}
          <Route
            path={PATH.TRANSACTIONS}
            element={
              <React.Suspense fallback={<Loading />}>
                <Transactions />
              </React.Suspense>
            }
          />
          {/** Help */}
          <Route
            path={PATH.HELP}
            element={
              <React.Suspense fallback={<Loading />}>
                <Help />
              </React.Suspense>
            }
          />
          {/** FAQ */}
          <Route
            path={PATH.FAQ_DETAIL}
            element={
              <React.Suspense fallback={<Loading />}>
                <FAQ />
              </React.Suspense>
            }
          />
          <Route
            path="*"
            element={
              <React.Suspense fallback={<Loading />}>
                <NotFound />
              </React.Suspense>
            }
          />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default withHook(AppRoutes, [
  { useHook: React.useState, hookName: 'useState', value: null },
]);
