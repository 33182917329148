import { Button, Tour } from 'antd';
import type { TourProps } from 'antd';
import { useLocation, useNavigate } from 'react-router';
import './Tour.scss';

interface TourComponentProps {
  open: boolean;
  setOpen: any;
  steps: TourProps['steps'];
  keyItem: string;
  indicators?: any;
}

const TourComponent = (props: TourComponentProps) => {
  const { open, setOpen, steps, keyItem, indicators } = props;
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Tour
      open={open}
      key={keyItem}
      onClose={() => {
        setOpen(false);
        navigate(location.pathname);
      }}
      mask={false}
      type="primary"
      scrollIntoViewOptions={false}
      zIndex={10000000}
      steps={steps}
      animated
      rootClassName="tour-container"
      indicatorsRender={(current, total) => {
        return indicators ? (
          <div className="indicators">
            <span
              className="btn-hide-tips"
              onClick={() => {
                setOpen(false);
                navigate(location.pathname);
              }}
            >
              Hide Tips
            </span>
            <span>
              {current + indicators?.start} / {indicators?.end}
            </span>
          </div>
        ) : (
          <div className="indicators">
            <span
              className="btn-hide-tips"
              onClick={() => {
                setOpen(false);

                if (
                  location.state?.tourName &&
                  location.state.tourName === 'store-connection-tour'
                ) {
                  navigate(location.pathname, {
                    state: {
                      tourName: 'store-connection-tour',
                      isConnectTour: {
                        open: false,
                        modal: false,
                      },
                    },
                  });
                } else {
                  navigate(location.pathname);
                }
              }}
            >
              Hide Tips
            </span>
            <span>
              {current + 1} / {total}
            </span>
          </div>
        );
      }}
    />
  );
};

export const renderTourPanel = (
  step: any,
  current: number,
  total: number,
  btns: { name: string; onClick: () => void; btnClassName: string }[],
  onClose: () => void,
) => {
  return (
    <div className="tour-panel-wrapper">
      <p className="tour-title">{step?.title}</p>
      <p className="tour-desc">{step?.description}</p>
      <div className="tour-footer">
        <div className="indicators">
          <span className="btn-hide-tips" onClick={onClose}>
            Hide Tips
          </span>
          <span>
            {current + 1} / {total}
          </span>
        </div>
        <div>
          {btns?.map((btn: { name: string; onClick: any; btnClassName: string }, i: number) => (
            <Button key={i} className={btn.btnClassName} onClick={btn.onClick}>
              {btn.name}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TourComponent;
