import moment from 'moment';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { memo, useContext, useEffect, useState } from 'react';
import { Modal, Spin } from 'antd';

import './index.scss';
import ShipmentInformation from './Components/ShipmentInformation';
import Addresses from './Components/Addresses';
import Service from './Components/Service';
import { Context } from '../../../ContextProvider';
import shipmentApi from '@/apis/workflow/shipment.api';
import { getLocalItem } from '@/helpers/storage';
import { Token_Info } from '@/constants/common';
import { FORMAT_DATETIME } from '@/constants/date';
import { getCountryNameByCountryCode } from '@/helpers/utils';

const ShipmentDetail = (props: any) => {
  const { isModalDetailOpen, setIsModalDetailOpen } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const context: any = useContext(Context);
  const [detailData, setDetailData]: any = useState({});
  const [isFinishLoadData, setIsFinishLoadData] = useState(false);
  const handleOk = () => {
    setIsModalDetailOpen(false);
    setIsFinishLoadData(false);
    if (location.search !== '') {
      navigate(location.pathname, {
        state: location.state,
      });
    }
  };

  useEffect(() => {
    if (context === null && location.search !== '') {
      const urlParams = new URLSearchParams(location.search);
      const shipmentId: any = urlParams.get('shipmentId');

      shipmentApi
        .getShipmentDetail(+shipmentId)
        .then((res: any) => {
          if (res?.Data) {
            const data = res.Data;
            const listItems: any = [];

            data?.ShipmentInformationItem?.map((item: any, index: number) =>
              listItems.push({
                Id: item?.Id,
                ItemDescription: item?.ItemDescription,
                CountryNameOfManufature: getCountryNameByCountryCode(item?.CountryOfManufature),
                CountryCodeOfManufature: item?.CountryOfManufature,
                HSTariffCode: item?.HSTariffCode,
                Qly: item?.Qly,
                DeclaredValue: item?.DeclaredValue,
                ItemWeight: item?.ItemWeight,
                TotalItemPrice: item?.TotalItemPrice,
              }),
            );

            let shipFromAddressInfo = '';
            let shipToAddressInfo = '';

            if (Object.keys(data?.Addresses)?.length > 0) {
              shipFromAddressInfo = `${
                data?.Addresses?.ShipFrom?.AdressLine1 !== null
                  ? data?.Addresses?.ShipFrom?.AdressLine1
                  : ''
              } ${
                data?.Addresses?.ShipFrom?.Province !== null
                  ? data?.Addresses?.ShipFrom?.Province
                  : ''
              } ${
                data?.Addresses?.ShipFrom?.City !== null ? data?.Addresses?.ShipFrom?.City : ''
              } ${
                data?.Addresses?.ShipFrom?.PostalCode !== null
                  ? data?.Addresses?.ShipFrom?.PostalCode
                  : ''
              } - ${
                data?.Addresses?.ShipFrom?.ContactName !== null
                  ? data?.Addresses?.ShipFrom?.ContactName
                  : ''
              } ${
                data?.Addresses?.ShipFrom?.CompanyName !== null
                  ? data?.Addresses?.ShipFrom?.CompanyName
                  : ''
              } ${
                data?.Addresses?.ShipFrom?.ContactNo !== null
                  ? data?.Addresses?.ShipFrom?.ContactNo
                  : ''
              } ${
                data?.Addresses?.ShipFrom?.Email !== null ? data?.Addresses?.ShipFrom?.Email : ''
              }`;

              shipToAddressInfo = `${
                data?.Addresses?.ShipTo?.AdressLine1 !== null
                  ? data?.Addresses?.ShipTo?.AdressLine1
                  : ''
              } ${
                data?.Addresses?.ShipTo?.Province !== null ? data?.Addresses?.ShipTo?.Province : ''
              } ${data?.Addresses?.ShipTo?.City !== null ? data?.Addresses?.ShipTo?.City : ''} ${
                data?.Addresses?.ShipTo?.PostalCode !== null
                  ? data?.Addresses?.ShipTo?.PostalCode
                  : ''
              } - ${
                data?.Addresses?.ShipTo?.ContactName !== null
                  ? data?.Addresses?.ShipTo?.ContactName
                  : ''
              } ${
                data?.Addresses?.ShipTo?.CompanyName !== null
                  ? data?.Addresses?.ShipTo?.CompanyName
                  : ''
              } ${
                data?.Addresses?.ShipTo?.ContactNo !== null
                  ? data?.Addresses?.ShipTo?.ContactNo
                  : ''
              } ${data?.Addresses?.ShipTo?.Email !== null ? data?.Addresses?.ShipTo?.Email : ''}`;
            }

            const result: any = {
              Id: data?.Id,
              OrderNumber: data?.OrderNumber,
              StoreName: data?.StoreName,
              ShipmentInformation: {
                Id: data?.ShipmentInformation?.Id,
                SellerNotes: data?.ShipmentInformation?.SellerNotes,
                Tags: data?.ShipmentInformation?.Tags?.split(','),
                TypeOfShipment: {
                  Id: data?.ShipmentInformation?.TypeOfShipment?.Id,
                  Type: data?.ShipmentInformation?.TypeOfShipment?.Type,
                },
                TypeOfGoods: {
                  Code: data?.ShipmentInformation?.TypeOfGoods?.Code,
                  Id: data?.ShipmentInformation?.TypeOfGoods?.Id,
                  Name: data?.ShipmentInformation?.TypeOfGoods?.Name,
                },
                TotalDeclaredValue: data?.ShipmentInformation?.TotalDeclaredValue,
                Measurements: {
                  Length: data?.ShipmentInformation?.Measurements?.Length,
                  Width: data?.ShipmentInformation?.Measurements?.Width,
                  Height: data?.ShipmentInformation?.Measurements?.Height,
                },
                Weight: data?.ShipmentInformation?.Weight,
              },
              ShipmentInformationItem: listItems,
              ShipmentStatus: data?.ShipmentStatus,
              TrackingNo: data?.TrackingNo,
              TrackingLink: data?.TrackingLink || '',
              Label: data?.Label,
              CreateShipmentDate: data?.CreateShipmentDate,
              Addresses: {
                ShipFrom: {
                  Id: data?.Addresses?.ShipFrom?.Id,
                  CompanyName: data?.Addresses?.ShipFrom?.CompanyName,
                  ContactNo: data?.Addresses?.ShipFrom?.ContactNo,
                  ContactName: data?.Addresses?.ShipFrom?.ContactName,
                  Email: data?.Addresses?.ShipFrom?.Email,
                  Country: data?.Addresses?.ShipFrom?.Country,
                  PostalCode: data?.Addresses?.ShipFrom?.PostalCode,
                  City: data?.Addresses?.ShipFrom?.City,
                  Province: data?.Addresses?.ShipFrom?.Province,
                  AdressLine1: data?.Addresses?.ShipFrom?.AdressLine1,
                  AddressLine2: data?.Addresses?.ShipFrom?.AddressLine2,
                  Address: shipFromAddressInfo,
                },
                ShipTo: {
                  Id: data?.Addresses?.ShipTo?.Id,
                  CompanyName: data?.Addresses?.ShipTo?.CompanyName,
                  ContactNo: data?.Addresses?.ShipTo?.ContactNo,
                  ContactName: data?.Addresses?.ShipTo?.ContactName,
                  Email: data?.Addresses?.ShipTo?.Email,
                  Country: data?.Addresses?.ShipTo?.Country,
                  PostalCode: data?.Addresses?.ShipTo?.PostalCode,
                  City: data?.Addresses?.ShipTo?.City,
                  Province: data?.Addresses?.ShipTo?.Province,
                  AdressLine1: data?.Addresses?.ShipTo?.AdressLine1,
                  AddressLine2: data?.Addresses?.ShipTo?.AddressLine2,
                  Address: shipToAddressInfo,
                },
              },
              Service: {
                Id: data?.Service?.Id,
                ServiceName: data?.Service?.ServiceName,
                EsitmatedTransitTime: data?.TransitTime,
                Price: data?.Service?.Price,
              },
            };

            setDetailData(result);
          }

          setIsFinishLoadData(true);
        })
        .catch((err: any) => {
          setIsFinishLoadData(true);
          console.log(err);
        });
    } else {
      setDetailData(context?.detailData);
      setIsFinishLoadData(context?.isFinishLoadData);
    }
  }, [location.search, context?.detailData]);

  return (
    <>
      <Modal
        open={isModalDetailOpen}
        onOk={handleOk}
        onCancel={handleOk}
        footer={false}
        className="detail-modal-wrapper"
        title="Shipment Information"
      >
        <Spin tip="Loading" spinning={isFinishLoadData === false}>
          <div className="header">
            <div className="info">
              <p>
                Tracking No:{' '}
                <Link
                  to={detailData.TrackingLink}
                  target="_blank"
                  state={location.state}
                  className="link"
                >
                  {detailData.TrackingNo}
                </Link>
              </p>
              <p>
                Created date at{' '}
                {detailData.CreateShipmentDate !== '' &&
                  moment(detailData.CreateShipmentDate).format(
                    FORMAT_DATETIME.MONTH_IS_TEXT_AND_SHOW_TIME,
                  )}
              </p>
            </div>
          </div>
          <div className="content">
            <div className="content-item address-section">
              <p className="title-main">ADDRESSES</p>
              <Addresses record={detailData} />
            </div>

            <div className="content-item shipment-section">
              <p className="title-main">SHIPMENT INFORMATION</p>
              <div className="content-wrapper">
                <ShipmentInformation record={detailData} />
              </div>
            </div>

            <div className="content-item service-section">
              <p className="title-main">SERVICE</p>
              <div className="content-wrapper">
                <Service record={detailData} />
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default memo(ShipmentDetail);
