import {
  accountChangePassword,
  createAddress,
  langtime,
  profile,
  store,
  storebilling,
  updateAddress,
} from '@/@types/api';
import BaseApi from '@apis/base.api';
import httpClient from '@apis/http-client.api';

class accountApi extends BaseApi {
  baseApi: string = '/spf/account';
  addressApi: string = '/address';
  profileApi: string = '/profile';
  storeApi: string = '/store';
  subcriptionApi: string = '/subcription';
  creditCardApi: string = '/credit-card';

  async getAddress(): Promise<any> {
    try {
      const response = await httpClient.httpGet(`${this.baseApi}${this.addressApi}/list`);
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async createAddress(data: createAddress): Promise<any> {
    try {
      const response = await httpClient.httpPost(`${this.baseApi}${this.addressApi}/create`, {
        CompanyName: data.CompanyName,
        ContactName: data.ContactName,
        ContactNo: data.ContactNo,
        Email: data.Email,
        CountryCode: data.CountryCode,
        Address1: data.Address1,
        Address2: data.Address2,
        PostalCode: data.PostalCode,
        Province: data.Province,
        City: data.City,
      });
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async updateAddress(data: updateAddress, id: string | number): Promise<any> {
    try {
      const response = await httpClient.httpPut(`${this.baseApi}${this.addressApi}/update/${id}`, {
        ContactName: data.ContactName,
        ContactNo: data.ContactNo,
        Email: data.Email,
        CountryCode: data.CountryCode,
        Address1: data.Address1,
        Address2: data.Address2,
        PostalCode: data.PostalCode,
        Province: data.Province,
        City: data.City,
      });
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getAddressDetail(): Promise<any> {
    try {
      const response = await httpClient.httpGet(`${this.baseApi}${this.addressApi}/detail`);
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getAddressDetails(id: string): Promise<any> {
    try {
      const response = await httpClient.httpGet(`${this.baseApi}${this.addressApi}/detail/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getAddressDelete(id: string): Promise<any> {
    try {
      const response = await httpClient.httpPatch(
        `${this.baseApi}${this.addressApi}/softDelete/${id}`,
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getAddressSelectDefault(id: string): Promise<any> {
    try {
      const response = await httpClient.httpPatch(
        `${this.baseApi}${this.addressApi}/selectDefault/${id}`,
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getProfile(): Promise<any> {
    try {
      const response = await httpClient.httpGet(`${this.baseApi}${this.profileApi}/detail`);
      return Promise.resolve(response.Data || []);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getProfileChangePassword(data: accountChangePassword): Promise<any> {
    try {
      const response = await httpClient.httpPut(
        `${this.baseApi}${this.profileApi}/changePassword`,
        {
          OldPassword: data.OldPassword,
          NewPassword: data.NewPassword,
          ConfirmNewPassword: data.ConfirmNewPassword,
        },
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async changePasswordProfile(newPassword: string, confirmPassword: string): Promise<any> {
    try {
      const response = await httpClient.httpPatch(
        `${this.baseApi}${this.profileApi}/changePassword`,
        {
          NewPassword: newPassword,
          ConfirmPassword: confirmPassword,
        },
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async updateProfile(data: profile): Promise<any> {
    try {
      const response = await httpClient.httpPut(`${this.baseApi}${this.profileApi}/update`, {
        FirstName: data.FirstName,
        CountryCode: data.CountryCode,
        ContactNo: data.ContactNo,
        NRIC: data.NRIC,
        UENNo: data.UENNo,
      });
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getLangTime(): Promise<any> {
    try {
      const response = await httpClient.httpGet(
        `${this.baseApi}${this.profileApi}/languageTimezone`,
      );
      return Promise.resolve(response.Data || []);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async updateLangTime(data: langtime): Promise<any> {
    try {
      const response = await httpClient.httpPut(
        `${this.baseApi}${this.profileApi}/languageTimezone/update`,
        {
          LanguageCode: data.Languages,
          TimezoneCode: data.TimezoneCode,
        },
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getStore(id: string): Promise<any> {
    try {
      const response = await httpClient.httpGet(`${this.baseApi}${this.storeApi}/detail/${id}`);
      return Promise.resolve(response.Data || []);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async updateStore(data: store, id: string): Promise<any> {
    try {
      const response = await httpClient.httpPut(`${this.baseApi}${this.storeApi}/update/${id}`, {
        StoreName: data.StoreName,
        HomepageUrl: data.HomepageUrl,
        ContactPageUrl: data.ContactPageUrl,
      });
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async updateBilling(data: storebilling, id: string): Promise<any> {
    try {
      const response = await httpClient.httpPost(
        `${this.baseApi}${this.storeApi}/billingInfomation/update`,
        {
          Id: id,
          FullName: data.FullName,
          Designation: data.Designation,
          Email: data.Email,
          Phone: data.Phone,
          BillingAddress: data.Address,
          Country: data.Country,
          PostalCode: data.PostalCode,
        },
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async creditCard_Listing(): Promise<any> {
    try {
      const response = await httpClient.httpGet(`${this.baseApi + this.creditCardApi}/list`);
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async creditCard_SelectDefault(id: string | number): Promise<any> {
    try {
      const response = await httpClient.httpPatch(
        `${this.baseApi + this.creditCardApi}/selectDefault/${id}`,
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async creditCard_Create(): Promise<any> {
    try {
      const response = await httpClient.httpPost(`${this.baseApi + this.creditCardApi}/create`);
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async creditCard_SoftDelete(id: string | number): Promise<any> {
    try {
      const response = await httpClient.httpPatch(
        `${this.baseApi + this.creditCardApi}/soft-delete/${id}`,
      );
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async subcriptionList(): Promise<any> {
    try {
      const response = await httpClient.httpGet(`${this.baseApi + this.subcriptionApi}/list`);
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async subcriptionPlan(): Promise<any> {
    try {
      const response = await httpClient.httpGet(`${this.baseApi + this.subcriptionApi}/common`);
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async subcriptionUpdate(id: string | number): Promise<any> {
    try {
      const response = await httpClient.httpPost(`${this.baseApi + this.subcriptionApi}/upgrade`, {
        PlanId: id,
      });
      return Promise.resolve(response);
    } catch (error) {
      return this.handleError(error);
    }
  }
}

export default new accountApi();
