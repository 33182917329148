import moment from 'moment';
import { Checkbox, Image, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useLocation, useNavigate } from 'react-router';
import ReactCountryFlag from 'react-country-flag';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';

import './ShipmentBasic.scss';
import { Context } from '../ContextProvider';
import FileIcon from '@assets/images/file.png';
import { FORMAT_DATETIME } from '@/constants/date';
import ShipmentDetail from './../Listing/Shipments/Detail';
import { ShipmentEnum } from '@/constants/common';
import { shipment } from '@/@types/api';
import ManageShipmentTour from '@/pages/Help/Tours/ManageShipment';
import Logo from '@/assets/images/brands/shopify-icon.png';

const antIconLoading = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ShipmentBasic: React.FC<any> = props => {
  const checkedRowRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalDetailOpen, setIsModalDetailOpen] = useState(false);
  const [openManageShipmentTour, setOpenManageShipmentTour] = useState(false);
  const context: any = useContext(Context);
  const { data, selectedRowKeys, onSelectRowChange, isLoadData, setSelectedRowKeys } = context;
  const [dataTabs, setDataTabs] = useState({
    dataAll: isLoadData ? data : [],
    dataSPSG: [],
  });
  const [state, setState] = useState({
    loading: isLoadData,
    activeKey: 'all',
  });

  const columns: ColumnsType<any> = [
    {
      title: 'Singpost ID',
      dataIndex: 'singpostId',
      fixed: 'left',
      render: (singpostId: any, record: shipment) => {
        const isCrossedOut =
          record?.action === ShipmentEnum.REFUND_STATUSES.CANCEL_REFUND_DONE || record?.invalid;

        return <div className={`cell-item ${isCrossedOut && 'crossed-out'}`}>{singpostId}</div>;
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Store + Order No.',
      dataIndex: 'storeAndOrderNo',
      width: 360,
      fixed: 'left',
      render: (order: any, record: shipment) => {
        const isCrossedOut =
          record?.action === ShipmentEnum.REFUND_STATUSES.CANCEL_REFUND_DONE || record?.invalid;

        return (
          <div className={`cell-item item-center ${isCrossedOut && 'crossed-out'}`}>
            <div className="img">
              <Image src={Logo} preview={false} className="integration-icon" />
            </div>
            <div className="info">
              <p className="info-shop">{order.nameOfShop}</p>
              <p className="info-item">
                <span>{order.totalItem} item(s)</span>
                <span>#{order.orderCode}</span>
              </p>
            </div>
          </div>
        );
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      render: (date: any, record: shipment) => {
        const isCrossedOut =
          record?.action === ShipmentEnum.REFUND_STATUSES.CANCEL_REFUND_DONE || record?.invalid;

        return (
          <div className={`cell-item ${isCrossedOut && 'crossed-out'}`}>
            {date === null
              ? ''
              : moment(date).format(FORMAT_DATETIME.FULL_DATE_FULL_TIME_AND_SHOW_TWELVE_FORMAT)}
          </div>
        );
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Recipient',
      dataIndex: 'recipient',
      width: 150,
      render: (senderAndReceiver: any, record: any, index: number) => {
        const emptyId = context.getEmptyItem(record.id);
        const isCrossedOut =
          record?.action === ShipmentEnum.REFUND_STATUSES.CANCEL_REFUND_DONE || record?.invalid;

        if (emptyId === undefined) {
          return (
            <div className={`cell-item item-center ${isCrossedOut && 'crossed-out'}`}>
              <div className="img">
                {!senderAndReceiver.countryCode ? (
                  <ExclamationCircleOutlined className="icon-alert" />
                ) : (
                  <ReactCountryFlag
                    countryCode={senderAndReceiver.countryCode}
                    svg
                    title={senderAndReceiver.countryCode}
                  />
                )}
              </div>
              <div className="info">
                <p className="info-shop">{senderAndReceiver.name}</p>
                <p className="info-item">{senderAndReceiver.country}</p>
              </div>
            </div>
          );
        } else {
          return (
            <div className="cell-item cell-empty highlight">
              <ExclamationCircleOutlined className="icon-alert" />
            </div>
          );
        }
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Service',
      dataIndex: 'service',
      render: (service: any, record: any) => {
        const emptyId = context.getEmptyItem(record.id);
        const isCrossedOut =
          record?.action === ShipmentEnum.REFUND_STATUSES.CANCEL_REFUND_DONE || record?.invalid;

        if (emptyId === undefined && !!service.serviceName) {
          return (
            <div className={`cell-item ${isCrossedOut && 'crossed-out'}`}>
              {service.serviceName}
            </div>
          );
        } else {
          return (
            <div className="cell-item cell-empty highlight">
              <ExclamationCircleOutlined className="icon-alert" />
            </div>
          );
        }
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Tracking No.',
      dataIndex: 'trackingNo',
      render: (tracking: any, record: any) => {
        const isCrossedOut =
          record?.action === ShipmentEnum.REFUND_STATUSES.CANCEL_REFUND_DONE || record?.invalid;

        return (
          <div className={`cell-item ${isCrossedOut && 'crossed-out'}`}>
            {isCrossedOut ? (
              <p
                className="link"
                onClick={e => {
                  e.stopPropagation();
                  setIsModalDetailOpen(prev => false);
                }}
              >
                {tracking}
              </p>
            ) : (
              <a
                href={record.trackingLink}
                className="link"
                target="_blank"
                rel="tracking link noreferrer"
                onClick={e => {
                  e.stopPropagation();
                  setIsModalDetailOpen(prev => false);
                }}
              >
                {tracking}
              </a>
            )}
          </div>
        );
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Label',
      dataIndex: 'label',
      width: 70,
      // responsive: ['sm'],
      align: 'center',
      render: (label: any, record: any) => {
        const isCrossedOut =
          record?.action === ShipmentEnum.REFUND_STATUSES.CANCEL_REFUND_DONE || record?.invalid;

        return label !== null ? (
          <div className={`cell-item label ${isCrossedOut && 'crossed-out'}`}>
            {isCrossedOut ? (
              <p
                className="link label-link"
                onClick={e => {
                  e.stopPropagation();
                  setIsModalDetailOpen(prev => false);
                }}
                rel="label noreferrer"
              >
                <img
                  src={FileIcon}
                  width={30}
                  height={30}
                  alt="label"
                  className="img-crossed-out"
                />
              </p>
            ) : (
              <a
                href={label}
                className="link"
                target="_blank"
                onClick={e => {
                  e.stopPropagation();
                  setIsModalDetailOpen(prev => false);
                }}
                rel="label noreferrer"
              >
                <img src={FileIcon} width={30} height={30} alt="label" />
              </a>
            )}
          </div>
        ) : null;
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Order Status',
      dataIndex: 'shipmentStatus',
      render: (shipmentStatus: any, record: shipment) => {
        const isCrossedOut =
          record?.action === ShipmentEnum.REFUND_STATUSES.CANCEL_REFUND_DONE || record?.invalid;

        return <div className={`cell-item ${isCrossedOut && 'crossed-out'}`}>{shipmentStatus}</div>;
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
  ];

  const rowSelection: any = {
    selectedRowKeys,
    onChange: onSelectRowChange,
    renderCell: (value: boolean, record: any, index: number, originNode: React.ReactNode) => {
      return openManageShipmentTour ? (
        <div
          ref={value ? checkedRowRef : undefined}
          onClick={() => {
            if (location.state?.tourName && openManageShipmentTour)
              navigate(location.pathname, {
                state: {
                  ...StaticRange,
                  tourName: 'manage-shipment',
                  isManageShipmentTour: {
                    step3: true,
                  },
                  isActionDropdownOpen: true,
                  isShipmentActive: true,
                },
              });
          }}
        >
          <Checkbox checked={value} />
        </div>
      ) : (
        originNode
      );
    },
  };

  function onRowClick(record: any): void {
    setIsModalDetailOpen(true);
    context.getDetailOfShipment(record.id);
  }

  useEffect(() => {
    if (location.state && location.state?.isManageShipmentTour) {
      if (location.state.isManageShipmentTour?.step2) {
        const id = data && data[0] && data[0]?.id;
        setSelectedRowKeys([id]);
        setOpenManageShipmentTour(true);
      } else {
        setOpenManageShipmentTour(false);
      }
    } else {
      setOpenManageShipmentTour(false);
    }
  }, [location.state]);

  useEffect(() => {
    let dataRender: any = [];
    let dataSGRender: any = [];
    data.map((val: any) => {
      if (val?.service?.serviceCode === 'IWCPSD') {
        dataSGRender.push(val);
      } else {
        dataRender.push(val);
      }
    });
    setDataTabs({
      dataAll: dataRender,
      dataSPSG: dataSGRender,
    });
  }, [data]);

  const changeTab = (key: any) => {
    renderData(data, key);
    setState({
      ...state,
      activeKey: key,
      loading: false,
    });
  };

  const renderData = (data: any, key: any) => {
    let dataRender: any = [];
    let dataSGRender: any = [];

    if (key === 'SPSG') {
      data.map((val: any) => {
        if (val?.service?.serviceCode === 'IWCPSD') {
          dataSGRender.push(val);
        } else {
          dataRender.push(val);
        }
      });
      setDataTabs({
        dataAll: dataRender,
        dataSPSG: dataSGRender,
      });
    } else {
      data.map((val: any) => {
        if (val?.service?.serviceCode === 'IWCPSD') {
          dataSGRender.push(val);
        } else {
          dataRender.push(val);
        }
      });
      setDataTabs({
        dataAll: dataRender,
        dataSPSG: dataSGRender,
      });
    }
  };

  const tabPanes = [
    {
      key: 'all',
      title: 'All other shipments',
      icon: '',
      content: (
        <Table
          className="shipment-table"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataTabs.dataAll}
          bordered
          pagination={false}
          tableLayout={'fixed'}
          scroll={{ x: 1440, y: 600 }}
          loading={{
            spinning: isLoadData ? true : false,
            tip: 'Loading',
            indicator: antIconLoading,
          }}
        />
      ),
      count: dataTabs.dataAll.length,
    },
    {
      key: 'SPSG',
      title: 'Speedpost Priority SG',
      icon: '',
      content: (
        <Table
          className="shipment-table"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataTabs.dataSPSG}
          bordered
          pagination={false}
          tableLayout={'fixed'}
          scroll={{ x: 1440, y: 600 }}
          loading={{
            spinning: isLoadData ? true : false,
            tip: 'Loading',
            indicator: antIconLoading,
          }}
        />
      ),
      count: dataTabs.dataSPSG.length > 0 ? dataTabs.dataSPSG.length : 0,
    },
  ];

  return (
    <>
      <Tabs
        type="card"
        activeKey={state.activeKey}
        onChange={changeTab}
        items={tabPanes.map(pane => {
          return {
            label: (
              <>
                <span>{pane.title}</span>
                <span>
                  <p>({pane.count})</p>
                </span>
              </>
            ),
            key: pane.key,
            children: pane.content,
          };
        })}
      />
      {isModalDetailOpen && (
        <ShipmentDetail
          isModalDetailOpen={isModalDetailOpen}
          setIsModalDetailOpen={setIsModalDetailOpen}
        />
      )}
      {openManageShipmentTour && (
        <ManageShipmentTour
          open={location.state?.isManageShipmentTour?.reactive || openManageShipmentTour}
          setOpen={setOpenManageShipmentTour}
          forwardRef={checkedRowRef}
          step={2}
        />
      )}
    </>
  );
};
export default ShipmentBasic;
