import moment from 'moment';
import { Image, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ReactCountryFlag from 'react-country-flag';
import React, { useContext, useState } from 'react';

import './ShipmentFulfilled.scss';
import FileIcon from '@assets/images/file.png';
import { Context } from '../ContextProvider';
import { FORMAT_DATETIME } from '@/constants/date';
import ShipmentDetail from './../Listing/Shipments/Detail';
import Logo from '@/assets/images/brands/shopify-icon.png';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';

const antIconLoading = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ShipmentFulfilled: React.FC<any> = props => {
  const [isModalDetailOpen, setIsModalDetailOpen] = useState(false);
  const context: any = useContext(Context);
  const { data, selectedRowKeys, onSelectRowChange, isLoadData } = context;

  const columns: ColumnsType<any> = [
    {
      title: 'Singpost ID',
      dataIndex: 'singpostId',
      fixed: 'left',
      render: (singpostId: any) => <div className="cell-item">{singpostId}</div>,
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Store + Order No.',
      dataIndex: 'storeAndOrderNo',
      width: 360,
      fixed: 'left',
      render: (order: any) => (
        <div className="cell-item item-center">
          <div className="img">
            <Image src={Logo} preview={false} className="integration-icon" />
          </div>
          <div className="info">
            <p className="info-shop">{order.nameOfShop}</p>
            <p className="info-item">
              <span>{order.totalItem} item(s)</span>
              <span>#{order.orderCode}</span>
            </p>
          </div>
        </div>
      ),
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      render: (date: any) => (
        <div className="cell-item">
          {date === null
            ? ''
            : moment(date).format(FORMAT_DATETIME.FULL_DATE_FULL_TIME_AND_SHOW_TWELVE_FORMAT)}
        </div>
      ),
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Recipient',
      dataIndex: 'recipient',
      width: 150,
      render: (senderAndReceiver: any, record: any, index: number) => {
        const emptyId = context.getEmptyItem(record.id);

        if (emptyId === undefined) {
          return (
            <div className="cell-item item-center">
              <div className="img">
                {!senderAndReceiver.countryCode ? (
                  <ExclamationCircleOutlined className="icon-alert" />
                ) : (
                  <ReactCountryFlag
                    countryCode={senderAndReceiver.countryCode}
                    svg
                    title={senderAndReceiver.countryCode}
                  />
                )}
              </div>
              <div className="info">
                <p className="info-shop">{senderAndReceiver.name}</p>
                <p className="info-item">{senderAndReceiver.country}</p>
              </div>
            </div>
          );
        } else {
          return (
            <div className="cell-item cell-empty highlight">
              <ExclamationCircleOutlined className="icon-alert" />
            </div>
          );
        }
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Service',
      dataIndex: 'service',
      render: (service: any, record: any) => {
        const emptyId = context.getEmptyItem(record.id);
        if (emptyId === undefined && !!service.serviceName) {
          return <div className="cell-item">{service.serviceName}</div>;
        } else {
          return (
            <div className="cell-item cell-empty highlight">
              <ExclamationCircleOutlined className="icon-alert" />
            </div>
          );
        }
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Tracking No.',
      dataIndex: 'trackingNo',
      render: (tracking: any, record: any) => (
        <div className="cell-item">
          <a
            href={record.trackingLink}
            className="link"
            target="_blank"
            rel="tracking link noreferrer"
            onClick={e => {
              e.stopPropagation();
              setIsModalDetailOpen(prev => false);
            }}
          >
            {tracking}
          </a>
        </div>
      ),
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Label',
      dataIndex: 'label',
      width: 70,
      align: 'center',
      render: (label: any, record: any) => {
        return label !== null ? (
          <div className="cell-item label">
            <a
              href={label}
              className="link"
              target="_blank"
              onClick={e => {
                e.stopPropagation();
                setIsModalDetailOpen(prev => false);
              }}
              rel="label noreferrer"
            >
              <img src={FileIcon} width={30} height={30} alt="label" />
            </a>
          </div>
        ) : null;
      },
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
    {
      title: 'Order Status',
      dataIndex: 'shipmentStatus',
      render: (shipmentStatus: any) => <div className="cell-item">{shipmentStatus}</div>,
      onCell: (record, rowIndex) => ({ onClick: e => onRowClick(record) }),
    },
  ];

  const rowSelection: any = {
    selectedRowKeys,
    onChange: onSelectRowChange,
  };

  function onRowClick(record: any): void {
    setIsModalDetailOpen(true);
    context.getDetailOfShipment(record.id);
  }

  return (
    <>
      <Table
        className="shipment-table"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        bordered
        pagination={false}
        tableLayout={'fixed'}
        scroll={{ x: 1440, y: 600 }}
        loading={{
          spinning: isLoadData ? true : false,
          tip: 'Loading',
          indicator: antIconLoading,
        }}
      />
      {isModalDetailOpen && (
        <ShipmentDetail
          isModalDetailOpen={isModalDetailOpen}
          setIsModalDetailOpen={setIsModalDetailOpen}
        />
      )}
    </>
  );
};
export default ShipmentFulfilled;
