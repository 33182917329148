import { useEffect, useState } from 'react';
import './App.scss';
import AppRoutes from '@/routes/routes';
import { Modal } from 'antd';
// import jwt_decode from 'jwt-decode';

const App = () => {
  const [expired, setExpired] = useState<boolean>(false);
  const tokenExpired = () => {
    Modal.warning({
      title: 'Token is expired',
      content: (
        <div>
          <p>Please log in again!!!</p>
        </div>
      ),
      onOk() {
        setExpired(false);
      },
    });
  };

  const token: any = localStorage.getItem('token');

  function tokenValid(token: any) {
    const now = Date.now() / 1000;
    const exp = token.exp;
    console.log('now: ' + now);
    console.log('exp: ' + exp);
    console.log('now - exp: ' + (now - exp));
    now > exp ? setExpired(true) : setExpired(false);
    setTimeout(tokenExpired, (exp - now) * 1000);
  }

  useEffect(() => {
    // token && tokenValid(jwt_decode(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expired]);

  return (
    <>
      {expired === true && tokenExpired()}
      <AppRoutes />
    </>
  );
};

export default App;
