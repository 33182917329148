import { useEffect } from 'react';
import type { TourProps } from 'antd';
import { useLocation, useNavigate } from 'react-router';

import { HelpEnum } from '@/constants/common';
import TourComponent, { renderTourPanel } from '../../../components/Tour/Tour';

interface ManageShipmentTourProps {
  open: boolean;
  setOpen: any;
  forwardRef?: any;
  step: number;
}

const ManageShipmentTour = (props: ManageShipmentTourProps) => {
  const { open, setOpen, forwardRef, step } = props;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.addEventListener('beforeunload', () => navigate(location.pathname));
    return () => {
      window.removeEventListener('beforeunload', () => {});
    };
  }, []);

  const renderStep = (step: number) => {
    switch (step) {
      case 1:
        return {
          step: 1,
          description: 'This is step 1',
          placement: 'right',
          btns: [
            {
              name: HelpEnum.STEPS.LETS_GO,
              btnClassName: 'next-btn',
              onClick: () => {
                navigate(location.pathname, {
                  state: {
                    tourName: 'manage-shipment',
                    isManageShipmentTour: {
                      step2: true,
                    },
                    isShipmentActive: true,
                  },
                });
              },
            },
          ],
        };

      case 2:
        return {
          step: 2,
          description: 'This is step 2',
          placement: 'right',
          btns: [
            {
              name: HelpEnum.STEPS.NEXT,
              btnClassName: 'next-btn',
              onClick: () => {
                navigate(location.pathname, {
                  state: {
                    tourName: 'manage-shipment',
                    isManageShipmentTour: {
                      step3: true,
                    },
                    isActionDropdownOpen: true,
                    isShipmentActive: true,
                  },
                });
              },
            },
          ],
        };

      case 3:
        return {
          step: 3,
          description: 'This is step 3',
          placement: 'right',
          btns: [
            {
              name: HelpEnum.STEPS.NEXT,
              btnClassName: 'next-btn',
              onClick: () => {
                navigate(location.pathname, {
                  state: {
                    tourName: 'manage-shipment',
                    isManageShipmentTour: {
                      step4: true,
                    },
                    isActionDropdownOpen: true,
                    isShipmentActive: true,
                  },
                });
              },
            },
          ],
        };

      case 4:
        return {
          step: 4,
          description: 'This is step 4',
          placement: 'right',
          btns: [
            {
              name: HelpEnum.STEPS.NEXT,
              btnClassName: 'next-btn',
              onClick: () => {
                navigate(location.pathname, {
                  state: {
                    tourName: 'manage-shipment',
                    isManageShipmentTour: {
                      step5: true,
                    },
                    isActionDropdownOpen: true,
                    isShipmentActive: true,
                  },
                });
              },
            },
          ],
        };

      case 5:
        return {
          step: 5,
          description: 'This is step 5',
          placement: 'right',
          btns: [
            {
              name: HelpEnum.STEPS.NEXT,
              btnClassName: 'next-btn',
              onClick: () => {
                setOpen(false);
                navigate(location.pathname, {
                  state: {
                    tourName: 'manage-shipment',
                    isManageShipmentTour: {
                      step6: true,
                    },
                    isShipmentActive: true,
                  },
                });
              },
            },
          ],
        };

      case 6:
        return {
          step: 6,
          description: 'This is step 6',
          placement: 'right',
          btns: [
            {
              name: HelpEnum.STEPS.DONE,
              btnClassName: 'next-btn',
              onClick: () => {
                navigate(location.pathname);
              },
            },
          ],
        };

      default:
        return {
          step: 'all',
          btns: [],
        };
    }
  };

  const steps: TourProps['steps'] = [
    {
      title: `step ${step}`,
      description: `${renderStep(step)?.description}`,
      target: () => forwardRef.current,
      current: step - 1,
      placement: 'right',
      renderPanel(stepProps, current) {
        const stepState = renderStep(step);

        return renderTourPanel(stepProps, current, 6, stepState?.btns, () => {
          setOpen(false);
          navigate(location.pathname);
        });
      },
    },
  ];

  return <TourComponent open={open} setOpen={setOpen} steps={steps} keyItem="ManageShipment" />;
};

export default ManageShipmentTour;
