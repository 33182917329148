export const PATH = {
  HOME: '/home',
  LOGIN: '/login',
  REGISTER: '/sign-up',
  REGISTER_SELLER: '/sign-up/seller',
  REGISTER_STORE: '/sign-up/store',
  REGISTER_SHIPPINGADDRESS: '/sign-up/shipping-address',
  REGISTER_INFORMATION: '/sign-up/information',
  REGISTER_BILLING: '/sign-up/billing',
  REGISTER_AGREEMENT: '/sign-up/agreement',
  REGISTER_SELECT_PLAN: '/sign-up/select-plan',
  REGISTER_CORPPASS_CALLBACK: '/callback',
  FORGOT_PASSWORD: '/forgot-password',
  SETUP_NEW_PASS: '/setup-new-password',
  LINK_EXPIRED: '/link-expired',
  ADDRESS: '/account/address',
  ACCOUNT_INFORMATIONS: '/account/information',
  SUBSCRIPTION: '/account/subscription',
  SHIPMENTS_MNG: '/shipments-mng',
  SHIPMENTS_MNG_PENDING: '/shipments-mng/shipment-pending',
  SHIPMENTS_MNG_SHIPMENT: '/shipments-mng/shipment',
  SHIPMENTS_MNG_FULFILLED: '/shipments-mng/shipment-fulfiled',
  SHIPMENTS_MNG_EDIT_SENDERANDRECEIVER: '/shipments-mng/edit/sender-and-receiver',
  SHIPMENTS_MNG_EDIT_SHIPMENT: '/shipments-mng/edit/shipment',
  SHIPMENTS_MNG_EDIT_SERVICE: '/shipments-mng/edit/service',
  REPORT_ORDER: '/report/order',
  CREATE_PICKUP: '/create-pickup',
  PICKUP: '/pickup',
  PICKUP_DROP_OFF: '/pickup/drop-off',
  PICKUP_COLLECTION_REQUEST: '/pickup/collection-request',
  PICKUP_SELF_LODGE: '/pickup/self-lodge',
  REPORT_SALES: '/report/sales',
  CONNECT: '/connect',
  CONNECTWITHPARAM: '/connect/:id',
  VERIFYSTORE: '/verify-shopify',
  CONNECT_WITH_NO_PARAM: '/connect',
  ACCOUNT_BOXES: '/account/boxes',
  PRIVACY_POLICY: '/privacy-policy',
  TERM_OF_USE: '/terms-use',
  TRANSACTIONS: '/transactions',
  CREDIT_NOTES: '/credit-notes',
  RECEIPTS: '/receipts',
  INVOICES: '/invoices',
  INVOICES_DETAIL: '/invoices/detail',
  NEW_SHIPMENT: '/new-shipment',
  NEW_SHIPMENT_CREATE_SENDERANDRECEIVER: '/new-shipment/create-shipment/sender-and-receiver',
  NEW_SHIPMENT_CREATE_SHIPMENT: '/new-shipment/create-shipment/shipment',
  NEW_SHIPMENT_CREATE_SERVICE: '/new-shipment/create-shipment/service',
  HELP: '/help',
  FAQ: '/FAQ',
  FAQ_DETAIL: '/help/FAQ/:name/:id',
};
