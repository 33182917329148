import { Col, Image, Popover, Row } from 'antd';
import { useLocation } from 'react-router';
import { createContext, useEffect, useState } from 'react';
import { CheckCircleFilled, ExclamationCircleOutlined, ShopOutlined } from '@ant-design/icons';

import { PATH } from '@/constants/paths';
import { Token_Info } from '@/constants/common';
import { getLocalItem } from '@/helpers/storage';
import shipmentApi from '@/apis/workflow/shipment.api';
import { shipment } from '@/@types/api';
import { getCountryNameByCountryCode } from '@/helpers/utils';
import Logo from '@/assets/images/brands/shopify-icon.png';

const Context = createContext(null);

const ContextProvider = ({
  children,
  data,
  setData,
  rowData,
  setRowData,
  listEmpty,
  isLoadData,
  setIsLoadData,
  selectedRowKeys,
  setSelectedRowKeys,
  onSelectRowChange,
  onSelectAllRow,
  onReloadData,
}: {
  children: any;
  data?: any[];
  setData?: any;
  rowData: any;
  setRowData: any;
  listEmpty?: any[];
  isLoadData?: boolean;
  setIsLoadData: any;
  selectedRowKeys?: React.Key[];
  setSelectedRowKeys: any;
  onSelectRowChange?: (newSelectedRowKeys: React.Key[], record: any) => void;
  onSelectAllRow?: () => void;
  onReloadData?: () => void;
}) => {
  const location = useLocation();
  const [countryList, setCountryList]: any = useState([{ label: 'Vietnam', value: 'VN' }]);
  const [isFinishLoadData, setIsFinishLoadData] = useState(false);
  const [shipmentOfRefundActionChosen, setShipmentOfRefundActionChosen] = useState<shipment>();
  const [addressOptions, setAddressOptions] = useState<any[]>([{ label: '', value: 0 }]);
  const [storeOptions, setStoreOptions] = useState<{ name: string; id: number }[]>([]);
  const [detailData, setDetailData]: any = useState({
    Id: 0,
    OrderNumber: '',
    StoreName: '',
    ShipmentInformation: {
      Id: 0,
      SellerNotes: '',
      Tags: [],
      TypeOfShipment: {
        Id: 2,
        Type: '',
      },
      TypeOfGoods: {
        Code: '',
        Id: 2,
        Name: '',
      },
      TotalDeclaredValue: '',
      Measurements: {
        Length: '',
        Width: '',
        Height: '',
      },
      Weight: '',
    },
    ShipmentInformationItem: [
      {
        Id: 0,
        ItemDescription: '',
        CountryNameOfManufature: '',
        CountryCodeOfManufature: '',
        HSTariffCode: '',
        DeclaredValue: '',
        ItemWeight: '',
        Qly: null,
        TotalItemPrice: '',
      },
    ],
    ShipmentStatus: '',
    TrackingNo: '',
    TrackingLink: '',
    Label: '',
    CreateShipmentDate: '',
    Addresses: {
      ShipFrom: {
        Id: 0,
        CompanyName: '',
        ContactNo: '',
        ContactName: '',
        Email: '',
        Country: '',
        PostalCode: '',
        City: '',
        Province: '',
        AdressLine1: '',
        AddressLine2: null,
        Address: '',
      },
      ShipTo: {
        Id: 0,
        CompanyName: '',
        ContactNo: '',
        ContactName: '',
        Email: '',
        Country: '',
        PostalCode: '',
        City: '',
        Province: null,
        AdressLine1: '',
        AddressLine2: null,
        Address: '',
      },
    },
    Service: {
      Id: 0,
      ServiceName: '',
      EsitmatedTransitTime: '',
      Price: null,
    },
  });

  useEffect(() => {
    const countries = getLocalItem(Token_Info?.Countries);
    const list: any = countries?.map((country: any) => {
      return { label: country.CountryName, value: country.CountryCode };
    });
    setCountryList(list);

    shipmentApi
      .getShipmentCommonList()
      .then((res: any) => {
        if (res?.Data) {
          let addressesList: any[] = [];
          for (const address of res.Data.listShipFromAddress) {
            addressesList.push({
              ...address,
              label: address.address1,
              value: address.id,
            });
          }

          setAddressOptions([...addressesList]);
          setStoreOptions([...res.Data.listStoreOfPlatforms]);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  function getDetailOfShipment(id: string | number): void {
    shipmentApi
      .getShipmentDetail(id)
      .then((res: any) => {
        if (res?.Data) {
          const data = res.Data;
          const listItems: any = [];

          data?.ShipmentInformationItem?.map((item: any, index: number) =>
            listItems.push({
              Id: item?.Id,
              ItemDescription: item?.ItemDescription,
              CountryNameOfManufature: getCountryNameByCountryCode(item?.CountryOfManufature),
              CountryCodeOfManufature: item?.CountryOfManufature,
              HSTariffCode: item?.HSTariffCode,
              Qly: item?.Qly,
              DeclaredValue: item?.DeclaredValue,
              ItemWeight: item?.ItemWeight,
              TotalItemPrice: item?.TotalItemPrice,
            }),
          );

          let shipFromAddressInfo = '';
          let shipToAddressInfo = '';

          if (Object.keys(data?.Addresses)?.length > 0) {
            shipFromAddressInfo = `${
              data?.Addresses?.ShipFrom?.AdressLine1 !== null
                ? data?.Addresses?.ShipFrom?.AdressLine1
                : ''
            } ${
              data?.Addresses?.ShipFrom?.Province !== null
                ? data?.Addresses?.ShipFrom?.Province
                : ''
            } ${data?.Addresses?.ShipFrom?.City !== null ? data?.Addresses?.ShipFrom?.City : ''} ${
              data?.Addresses?.ShipFrom?.PostalCode !== null
                ? data?.Addresses?.ShipFrom?.PostalCode
                : ''
            } - ${
              data?.Addresses?.ShipFrom?.ContactName !== null
                ? data?.Addresses?.ShipFrom?.ContactName
                : ''
            } ${
              data?.Addresses?.ShipFrom?.CompanyName !== null
                ? data?.Addresses?.ShipFrom?.CompanyName
                : ''
            } ${
              data?.Addresses?.ShipFrom?.ContactNo !== null
                ? data?.Addresses?.ShipFrom?.ContactNo
                : ''
            } ${data?.Addresses?.ShipFrom?.Email !== null ? data?.Addresses?.ShipFrom?.Email : ''}`;

            shipToAddressInfo = `${
              data?.Addresses?.ShipTo?.AdressLine1 !== null
                ? data?.Addresses?.ShipTo?.AdressLine1
                : ''
            } ${
              data?.Addresses?.ShipTo?.Province !== null ? data?.Addresses?.ShipTo?.Province : ''
            } ${data?.Addresses?.ShipTo?.City !== null ? data?.Addresses?.ShipTo?.City : ''} ${
              data?.Addresses?.ShipTo?.PostalCode !== null
                ? data?.Addresses?.ShipTo?.PostalCode
                : ''
            } - ${
              data?.Addresses?.ShipTo?.ContactName !== null
                ? data?.Addresses?.ShipTo?.ContactName
                : ''
            } ${
              data?.Addresses?.ShipTo?.CompanyName !== null
                ? data?.Addresses?.ShipTo?.CompanyName
                : ''
            } ${
              data?.Addresses?.ShipTo?.ContactNo !== null ? data?.Addresses?.ShipTo?.ContactNo : ''
            } ${data?.Addresses?.ShipTo?.Email !== null ? data?.Addresses?.ShipTo?.Email : ''}`;
          }

          const result: any = {
            Id: data?.Id,
            OrderNumber: data?.OrderNumber,
            StoreName: data?.StoreName,
            ShipmentInformation: {
              Id: data?.ShipmentInformation?.Id,
              SellerNotes: data?.ShipmentInformation?.SellerNotes,
              Tags: data?.ShipmentInformation?.Tags?.split(','),
              TypeOfShipment: {
                Id: data?.ShipmentInformation?.TypeOfShipment?.Id,
                Type: data?.ShipmentInformation?.TypeOfShipment?.Type,
              },
              TypeOfGoods: {
                Code: data?.ShipmentInformation?.TypeOfGoods?.Code,
                Id: data?.ShipmentInformation?.TypeOfGoods?.Id,
                Name: data?.ShipmentInformation?.TypeOfGoods?.Name,
              },
              TotalDeclaredValue: data?.ShipmentInformation?.TotalDeclaredValue,
              Measurements: {
                Length: data?.ShipmentInformation?.Measurements?.Length,
                Width: data?.ShipmentInformation?.Measurements?.Width,
                Height: data?.ShipmentInformation?.Measurements?.Height,
              },
              Weight: data?.ShipmentInformation?.Weight,
            },
            ShipmentInformationItem: listItems,
            ShipmentStatus: data?.ShipmentStatus,
            TrackingNo: data?.TrackingNo,
            TrackingLink: data?.TrackingLink,
            Label: data?.Label,
            CreateShipmentDate: data?.CreateShipmentDate,
            Addresses: {
              ShipFrom: {
                Id: data?.Addresses?.ShipFrom?.Id,
                CompanyName: data?.Addresses?.ShipFrom?.CompanyName,
                ContactNo: data?.Addresses?.ShipFrom?.ContactNo,
                ContactName: data?.Addresses?.ShipFrom?.ContactName,
                Email: data?.Addresses?.ShipFrom?.Email,
                Country: data?.Addresses?.ShipFrom?.Country,
                PostalCode: data?.Addresses?.ShipFrom?.PostalCode,
                City: data?.Addresses?.ShipFrom?.City,
                Province: data?.Addresses?.ShipFrom?.Province,
                AdressLine1: data?.Addresses?.ShipFrom?.AdressLine1,
                AddressLine2: data?.Addresses?.ShipFrom?.AddressLine2,
                Address: shipFromAddressInfo,
              },
              ShipTo: {
                Id: data?.Addresses?.ShipTo?.Id,
                CompanyName: data?.Addresses?.ShipTo?.CompanyName,
                ContactNo: data?.Addresses?.ShipTo?.ContactNo,
                ContactName: data?.Addresses?.ShipTo?.ContactName,
                Email: data?.Addresses?.ShipTo?.Email,
                Country: data?.Addresses?.ShipTo?.Country,
                PostalCode: data?.Addresses?.ShipTo?.PostalCode,
                City: data?.Addresses?.ShipTo?.City,
                Province: data?.Addresses?.ShipTo?.Province,
                AdressLine1: data?.Addresses?.ShipTo?.AdressLine1,
                AddressLine2: data?.Addresses?.ShipTo?.AddressLine2,
                Address: shipToAddressInfo,
              },
            },
            Service: {
              Id: data?.Service?.Id,
              ServiceName: data?.Service?.ServiceName,
              EsitmatedTransitTime: data?.TransitTime,
              Price: data?.Service?.Price,
            },
          };

          setDetailData(result);
        }

        setIsFinishLoadData(true);
      })
      .catch((err: any) => {
        setIsFinishLoadData(true);
        console.log(err);
      });
  }

  const getEmptyItem = (id: string | number) => {
    const item = listEmpty?.find((item: any) => item === id);
    return item;
  };

  const renderStoreAndOrderNo = (order: any, record: shipment) => {
    const isExactPath =
      (location.pathname.search(PATH.SHIPMENTS_MNG_PENDING) > -1 ||
        location.pathname.search(PATH.SHIPMENTS_MNG) > -1) &&
      location.pathname !== PATH.SHIPMENTS_MNG_SHIPMENT &&
      location.pathname !== PATH.SHIPMENTS_MNG_FULFILLED;

    const manual = record?.isManual;
    const isCrossedOut = record?.invalid;
    const isValid: any =
      (location.pathname === PATH.SHIPMENTS_MNG_PENDING &&
        record?.service?.maxItem <= order.totalItem) ||
      record?.service?.maxWeight < parseInt(record?.storeAndOrderNo?.weight)
        ? true
        : false;
    return (
      <Row className="d-flex justify-content-between align-items-center">
        <Col
          className={`cell-item item-center store-and-order-no-item ${
            isCrossedOut && 'crossed-out'
          }`}
          span={21}
        >
          <div className="img">
            {manual === false && isExactPath ? (
              <Image src={Logo} preview={false} className="integration-icon" />
            ) : (
              <ShopOutlined />
            )}
          </div>
          <div className="info">
            <p className="info-shop">{order.nameOfShop}</p>
            {location.pathname === PATH.SHIPMENTS_MNG_PENDING && isValid ? (
              <p className={`info-item highlight`}>
                <span className="total-item">{order.totalItem} item(s)</span>
                <span>#{order.orderCode}</span>
              </p>
            ) : (
              <p className={`info-item`}>
                <span className="total-item">{order.totalItem} item(s)</span>
                <span>#{order.orderCode}</span>
              </p>
            )}
          </div>
        </Col>
        {
          // record.orderType === ShipmentEnum.NOT_READY_TO_BE_SHIPPED
          record.totalChecked === false ||
            (location.pathname === PATH.SHIPMENTS_MNG_PENDING && isValid && (
              <Col span={3} className="cell-item item-icon">
                <Popover content="Sum all fee" trigger="click" className="popover-icon success">
                  <ExclamationCircleOutlined
                    className="icon-alert success"
                    title="Not available create shipment"
                  />
                </Popover>
              </Col>
            ))
        }
        {
          // record.orderType === ShipmentEnum.READY_TO_BE_SHIPPED
          record.totalChecked ||
            (location.pathname !== PATH.SHIPMENTS_MNG_PENDING && !isValid && (
              <Col span={3} className="cell-item item-icon">
                <Popover content="Sum all fee" trigger="click" className="popover-icon">
                  <CheckCircleFilled className="icon-alert" title="Can create shipment" />
                </Popover>
              </Col>
            ))
        }
      </Row>
    );
  };

  const value: any = {
    countryList,
    detailData,
    setDetailData,
    getDetailOfShipment,
    isFinishLoadData,
    setIsFinishLoadData,
    getEmptyItem,
    renderStoreAndOrderNo,
    data,
    setData,
    rowData,
    setRowData,
    listEmpty,
    isLoadData,
    setIsLoadData,
    selectedRowKeys,
    setSelectedRowKeys,
    onSelectRowChange,
    onSelectAllRow,
    shipmentOfRefundActionChosen,
    setShipmentOfRefundActionChosen,
    onReloadData,
    storeOptions,
    addressOptions,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { Context, ContextProvider };
