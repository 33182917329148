import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { memo, useContext, useEffect, useState } from 'react';

import './index.scss';
import { Context } from '@/pages/ShipmentsManagement/ContextProvider';
import { useLocation } from 'react-router';
import { random } from 'lodash';

const Service = ({record} : any) => {
  const location = useLocation();
  const context: any = useContext(Context);
  const [detailData, setDetailData]: any = useState({});

  interface DataType {
    key: number;
    id: number;
    serviceName: string;
    estimatedTransitTime: string;
    price: string;
  }

  const services: DataType[] = [
    {
      key: random() * 10,
      id: detailData?.Service?.Id,
      serviceName: detailData?.Service?.ServiceName,
      estimatedTransitTime: detailData?.Service?.EsitmatedTransitTime,
      price: detailData?.Service?.Price,
    },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: 'Service name',
      dataIndex: 'serviceName',
      key: 'serviceName',
    },
    {
      title: 'Estimated transit time',
      dataIndex: 'estimatedTransitTime',
      key: 'estimatedTransitTime',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: text => (
        <div className="d-flex justify-content-between">
          <span>{text}</span>
          <span>SGD</span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (context === null && location.search !== '') {
      if(Object.keys(record)?.length > 0){
        setDetailData(record);
      }
    } else {
      if(context.detailData){
        setDetailData(context.detailData)
      }
    }
  }, [record, context?.detailData]);

  return (
    <Table
      columns={columns}
      dataSource={services}
      bordered
      tableLayout="fixed"
      pagination={false}
      className="table-service"
    />
  );
};

export default memo(Service);
