import { StatusEnum, Token_Info } from '@/constants/common';
import { PATH } from '@/constants/paths';
import { removeLocalItem, setLocalItem } from '@/helpers/storage';
import { showError } from '@components/Notification/Notification';
import Utils from '@helpers/utils';
import { toast } from 'react-toastify';

abstract class BaseApi {
  /**
   * Get error message
   * @param error any
   * @returns string
   */
  protected static getServerErrorMessage(errors: any): string | null {
    let errMsg: string | null;
    if (errors.status === 400) {
      // The response body may contain clues as to what went wrong,
      if (typeof errors.error === 'object') {
        errMsg = errors.error.message
          ? errors.errors.message
          : errors.error.error
          ? errors.error.error
          : Utils.defaultErrorBody;
      } else errMsg = errors.error;
    } else {
      if (errors.status === 401) {
        errMsg = null;
      } else {
        if (errors.error && typeof errors.error === 'object') {
          errMsg = errors.error.ExceptionMessage
            ? errors.error.ExceptionMessage
            : errors.error.message
            ? errors.error.message
            : errors.error.error
            ? errors.error.error
            : Utils.defaultErrorBody;
        } else {
          errMsg = errors.error
            ? errors.error
            : errors.status
            ? `${errors.status} - ${errors.statusText}`
            : Utils.defaultErrorBody;
        }
      }
    }
    return errMsg;
  }

  /**
   * Handle HTTP error
   */
  protected handleError(error: any) {
    removeLocalItem(Token_Info?.Expired);
    removeLocalItem(Token_Info?.Deactive);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      const errMsg = error.statusText
        ? error.statusText
        : error.status
        ? `${error.status} - ${error.statusText}`
        : Utils.defaultErrorBody;
      return showError(errMsg);
    } else if (error.errorCode === '1') {
      removeLocalItem(Token_Info.Access_Token);
      removeLocalItem(Token_Info.User_Info);
      removeLocalItem(Token_Info.Plan);
      removeLocalItem(Token_Info.PlanRequest);
      setLocalItem(Token_Info.Deactive, true);
      // window.location.replace(
      //   `${process.env.PUBLIC_URL}${PATH.LOGIN}?redirectUrl=${window.location.pathname.slice(1)}`,
      // );
      window.location.replace(`${process.env.PUBLIC_URL}${PATH.LOGIN}`);
    } else if (error.errorCode === '7') {
      removeLocalItem(Token_Info.Access_Token);
      removeLocalItem(Token_Info.User_Info);
      removeLocalItem(Token_Info.Plan);
      removeLocalItem(Token_Info.PlanRequest);
      // window.location.replace(
      //   `${process.env.PUBLIC_URL}${PATH.LOGIN}?redirectUrl=${window.location.pathname.slice(1)}`,
      // );
      window.location.replace(`${process.env.PUBLIC_URL}${PATH.LOGIN}`);
    } else if (error.errorCode === '6') {
      setLocalItem(Token_Info.Expired, true);
      removeLocalItem(Token_Info.Access_Token);
      removeLocalItem(Token_Info.User_Info);
      removeLocalItem(Token_Info.Plan);
      removeLocalItem(Token_Info.PlanRequest);
      // window.location.replace(
      //   `${process.env.PUBLIC_URL}${PATH.LOGIN}?redirectUrl=${window.location.pathname.slice(1)}`,
      // );
      window.location.replace(`${process.env.PUBLIC_URL}${PATH.LOGIN}`);
      // return showError(Utils.sectionExpired);
    } else {
      // The backend returned an unsuccessful response code.
      const errMsg = BaseApi.getServerErrorMessage(error);
      return showError(errMsg);
    }
  }
}

export default BaseApi;
